import { createColumnHelper } from "@tanstack/react-table";
import { BlackEyeIcon, CommonButton, Notification } from "gov-ua-ui";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { docTypes } from "constant";
import features from "features";
import { formatDate } from "helpers";
import { IRootState } from "reducer";
import { IOfficialAnnulledApplicationsForSigning } from "../ducks";

import EDocButton from "components/buttons/EDocButton/EDocButton";
import PersonalOfficeOfficialLayout from "components/layouts/PersonalOfficeOfficialLayout/PersonalOfficeOfficialLayout";
import CommonTable from "components/tables/CommonTable/CommonTable";

import EdocIcon from "assets/images/icons/e_doc_application.svg";

import Preloader from "components/Preloader/Preloader";
import styles from "./official-annulled-applications-for-signing.module.scss";

interface iTableData {
  num?: number;
  uuid: string;
  certificateNumber: string;
  createdAt: number;
  consigneeName?: string;
  exporterName: string;
  buyerName: string;
  eCertificate: any;
  eApplication: any;
  show: any;
}

const OfficialAnnulledApplicationsForSigning = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<iTableData>();

  const { annulled, isLoadingFetchAnnulled } = useSelector<
    IRootState,
    IOfficialAnnulledApplicationsForSigning
  >((state) => state.officialAnnulledApplicationsForSigning);

  useEffect(() => {
    dispatch(
      features.officialAnnulledApplicationsForSigning.actions.fetchAnnulledRequest()
    );
  }, [dispatch]);

  const columns = [
    columnHelper.accessor("num", {
      cell: ({ row }) => (
        <p className={styles["drafts-page__table-number"]}>
          {parseInt(row.id) + 1}
        </p>
      ),
      header: () => "#"
    }),
    columnHelper.accessor("certificateNumber", {
      cell: (info: any) => (
        <p className={styles["drafts-page__table-number"]}>{info.getValue()}</p>
      ),
      header: () => "Номер"
    }),
    columnHelper.accessor("createdAt", {
      cell: (info: any) => formatDate(info.getValue()).date,
      header: () => "Дата видачі"
    }),
    // columnHelper.accessor("consigneeName", {
    //   cell: (info: any) => info.getValue(),
    //   header: () => "Лісокористувач / Продавець"
    // }),
    columnHelper.accessor("exporterName", {
      cell: (info: any) => info.getValue(),
      header: () => "Експортер"
    }),
    columnHelper.accessor("buyerName", {
      cell: (info: any) => info.getValue(),
      header: () => "Покупець"
    }),
    columnHelper.accessor("eCertificate", {
      cell: (info: any) => {
        const eCertificate = info.getValue();

        return (
          <EDocButton
            title={eCertificate.title}
            contentTitle={eCertificate.name}
            contentSubtitle={eCertificate.certificateId}
            contentText={eCertificate.date}
            type={docTypes.EDOC}
            onClick={() => onDocumentView(eCertificate)}
          />
        );
      },
      header: () => "єСертифікат"
    }),
    columnHelper.accessor("eApplication", {
      cell: (info: any) => {
        const eApplication = info.getValue();
        return (
          <EDocButton
            title={eApplication.title}
            contentTitle={eApplication.name}
            contentSubtitle={eApplication.serialNumber}
            contentText={eApplication.date}
            type={docTypes.APPLICATION}
            onClick={() => onDocumentView(eApplication)}
          />
        );
      },
      header: () => "еЗаява"
    }),
    columnHelper.accessor("show", {
      cell: (info) => (
        <CommonButton
          image={<BlackEyeIcon />}
          round={true}
          outlined={true}
          onClick={() => {
            handleShow(
              `/official/applications/annulled/${info.row.original.uuid}`
            );
          }}
        />
      ),
      header: () => ""
    })
  ];

  const onDocumentView = (file) => {
    window.open(file.filePath, "_blank");
  };

  const handleShow = (path: string) => {
    navigate(path);
  };

  const signAnnulled = useCallback(() => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "CONFIRM_ACTION",
        modalProps: {
          acceptLabel: "Так, продовжити",
          onAccept: () => {
            dispatch(
              features.officialAnnulledApplicationsForSigning.actions.signAnnulledRequest(
                {
                  onSuccess: () =>
                    dispatch(
                      features.officialDashboard.actions.fetchStatisticsRequest()
                    )
                }
              )
            );
          }
        }
      })
    );
  }, [dispatch]);

  const dates = useMemo(() => {
    const annulledDates = annulled.map((item) => item.createdAt);

    return `${formatDate(Math.min(...annulledDates)).date} - ${
      formatDate(Math.max(...annulledDates)).date
    }`;
  }, [annulled]);

  return (
    <PersonalOfficeOfficialLayout>
      {isLoadingFetchAnnulled ? (
        <Preloader />
      ) : (
        <div className={styles["personal-cabinet-page"]}>
          {annulled.length > 0 ? (
            <div className={styles["container-border"]}>
              <div className={styles["container-border__bordered-header"]}>
                <div className={styles["statement"]}>
                  <h4 className={styles["statement__title"]}>Заяв</h4>
                  <div className={styles["statement-content"]}>
                    <img src={EdocIcon} alt="document" />
                    <h1 className={styles["statement-content__count"]}>
                      {annulled.length}
                    </h1>
                  </div>
                </div>
                <div className={styles["dates"]}>
                  <h4 className={styles["statement__title"]}>Дати</h4>
                  <h4 className={styles["dates__date"]}>{dates}</h4>
                </div>
              </div>
              <CommonTable columns={columns} defaultData={annulled} />
              <CommonButton
                className={styles["container-border__annul-button"]}
                onClick={signAnnulled}
                label="Перевірити та анулювати"
              />
            </div>
          ) : (
            <Notification text="Всі заяви на анулювання підписані" />
          )}
        </div>
      )}
    </PersonalOfficeOfficialLayout>
  );
};

export default OfficialAnnulledApplicationsForSigning;
