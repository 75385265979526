import cn from "classnames";
import { useCallback, useState } from "react";

import DocumentView from "components/DocumentView/DocumentView";
import CommonTable from "components/tables/CommonTable/CommonTable";

import {
  CommonButton,
  SwitchButton,
  VisualUploadMultipleFiles
} from "gov-ua-ui";

import EyeIcon from "assets/images/icons/eye.svg";
import DocIcon from "assets/images/icons/e_doc.svg";

import styles from "../../basis-doc-view-modal.module.scss";

interface iChildrenProducts {
  title?: JSX.Element | string;
  data: any;
  columns: any;
  selectedRows: any;
  selectedWasteRows?: any;
  geometricDimension: any;
  wasteGeometricDimension?: any;
  isGrouped?: boolean;
  isMultiple?: boolean;
  handlePreviousUsageClick?: () => void;
}

const ChildrenProducts = ({
  data,
  columns,
  selectedRows,
  selectedWasteRows,
  geometricDimension,
  wasteGeometricDimension,
  title,
  isMultiple,
  isGrouped,
  handlePreviousUsageClick
}: iChildrenProducts) => {
  const [isLocalGrouped, setIsLocalGrouped] = useState(false);

  const handleRadioButtonClick = useCallback(() => {
    setIsLocalGrouped((prevState) => !prevState);
  }, []);

  const onDocumentView = (file) => {
    window.open(file.filePath, "_blank");
  };

  return (
    <div>
      <div
        className={
          isMultiple ? styles["children-head"] : styles["children-head--full"]
        }
      >
        {title && (
          <div className={styles["primary-document-modal__general-title"]}>
            {title}
          </div>
        )}
        {data?.docType === "zn" &&
          (data?.files?.length > 0 && isMultiple ? (
            <VisualUploadMultipleFiles
              containerClassName={styles["children-files"]}
              loadedFiles={data.files}
              onDocumentView={onDocumentView}
              readonly
            />
          ) : (
            <DocumentView
              docImg={DocIcon}
              className={styles["children-files__documen-view"]}
              isEOD={true}
            />
          ))}
        <div className={styles["primary-document-modal__general-actions"]}>
          {!isMultiple && (
            <>
              <CommonButton
                className={styles["primary-document-modal__controls-button"]}
                label="Попереднє використання"
                outlined={true}
                image={EyeIcon}
                onClick={handlePreviousUsageClick}
              />
              <div
                className={cn(
                  styles["primary-document-modal__switch-wrapper"],
                  styles["primary-document-modal__main-info-single-small"]
                )}
              >
                <SwitchButton
                  active={isLocalGrouped}
                  onChange={handleRadioButtonClick}
                />
                <h4 className={styles["primary-document-modal__switch-title"]}>
                  Згрупувати
                </h4>
              </div>
            </>
          )}
          <div
            className={cn(
              styles["primary-document-modal__main-info-single-wrapper"],
              styles["primary-document-modal__main-info-single-small"]
            )}
          >
            <h4 className={styles["primary-document-modal__main-info-label"]}>
              Геометричні розміри
            </h4>
            <h4 className={styles["primary-document-modal__main-info-text"]}>
              {geometricDimension}
            </h4>
          </div>
        </div>
      </div>

      <CommonTable
        defaultData={data.products}
        columns={columns}
        groupData={isGrouped ?? isLocalGrouped}
        footerTitle="Всього"
        footer={[
          (() =>
            data.docType !== "woodCard" && {
              name: "original",
              label: "Оригінал",
              value: data.quantityOrigin
            })(),
          {
            name: "decommissioned",
            label: "Списано",
            value: data.decommissioned
          }
        ]}
        rowSelection={selectedRows}
      />
      {data.wasteProducts?.length > 0 && (
        <div className={styles["primary-document__return-waste"]}>
          <div className={styles["primary-document-modal__general-title"]}>
            Зворотні відходи, отримані в результаті переробки
          </div>
          <br />
          <div className={styles["primary-document-modal__general-actions"]}>
            <div
              className={cn(
                styles["primary-document-modal__main-info-single-wrapper"],
                styles["primary-document-modal__main-info-single-small"]
              )}
            >
              <h4 className={styles["primary-document-modal__main-info-label"]}>
                Геометричні розміри
              </h4>
              <h4 className={styles["primary-document-modal__main-info-text"]}>
                {wasteGeometricDimension}
              </h4>
            </div>
          </div>
          <CommonTable
            defaultData={data.wasteProducts}
            columns={columns}
            footerTitle="Всього"
            footer={[
              (() =>
                data.docType !== "woodCard" && {
                  name: "original",
                  label: "Оригінал",
                  value: `${data.wasteQuantity} (${data.wasteQuantityPercent}%)`
                })(),
              {
                name: "decommissioned",
                label: "Списано",
                value: data.wasteDecommissioned
              }
            ]}
            rowSelection={selectedWasteRows}
          />
        </div>
      )}
    </div>
  );
};

export default ChildrenProducts;
