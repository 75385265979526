import { VisualUploadMultipleFiles } from "gov-ua-ui";
import { isEmpty } from "lodash";

import { parseFilesErrors } from "helpers";

const SyncVisualMultipleUploader = ({ loadedFiles, setLoadedFiles }) => {
  // const dispatch = useDispatch();

  const onLoadFiles = (acceptedFiles, rejectedFiles, replaceFile) => {
    if (!isEmpty(rejectedFiles)) {
      return parseFilesErrors(rejectedFiles);
    }
    if (acceptedFiles) {
      setLoadedFiles((prev) => [...prev, ...acceptedFiles]);
    }
    if (replaceFile) {
      loadedFiles.splice(loadedFiles.indexOf(replaceFile), 1, ...acceptedFiles);
      setLoadedFiles(loadedFiles);
    }
  };

  const onDeleteFile = async (file) => {
    await new Promise((resolve) => {
      loadedFiles.splice(loadedFiles.indexOf(file), 1);
      setLoadedFiles(loadedFiles);
      resolve(() => true);
      if (file?.cancel) {
        file.cancel();
      }
    });
  };

  const onModalOpen = (file) => {
    window.open(file.filePath, "_blank");

    // dispatch(
    //   features.modal.actions.showModal({
    //     modalType: getDocumentViewType(file.name),
    //     modalProps: { url: file.preview }
    //   })
    // );
  };

  return (
    <VisualUploadMultipleFiles
      title={"Завантажити"}
      accept={{
        "image/jpeg": [".jpeg"],
        "image/jpg": [".jpg"],
        "image/png": [".png"],
        "application/pdf": [".pdf"]
      }}
      maxSize={5}
      loadedFiles={loadedFiles}
      onLoad={onLoadFiles}
      onDelete={onDeleteFile}
      onDocumentView={onModalOpen}
    />
  );
};

export default SyncVisualMultipleUploader;
