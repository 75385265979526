import cn from "classnames";
import { CommonButton, SwitchButton } from "gov-ua-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import features from "features";
import {
  calculateTotals,
  formatDate,
  formatNumber,
  generateColumnData,
  getRole,
  parseResErrors,
  productResParser
} from "helpers";
import { IRootState } from "reducer";
import { IApplicationReviewState } from "scenes/official/certificates/OfficialApplicationReviewPage/ducks";
import { IApplicationState } from "scenes/subject/applications/ApplicationPage/ducks";
import { IProductUsageState } from "scenes/subject/documents/ProductUsage/ducks";

import CreateDocForm from "components/CreateDocForm/CreateDocForm";
import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";
import ModalWrapper from "components/modals/ModalWrapper/ModalWrapper";
import ChildrenProducts from "./components/ChildrenProducts/ChildrenProducts";

import EyeIcon from "assets/images/icons/eye.svg";

import styles from "./basis-doc-view-modal.module.scss";

interface BasisDocumentViewModalInterface {
  docUuid: string;
  docId: string | Array<number>;
  isPrimal: boolean;
  docType: string;
  onModalClose?: () => void;
}

const BasisDocViewModal = () => {
  const isOfficial = getRole() !== "user";
  const dispatch = useDispatch();

  const { docId, docUuid, docType, isPrimal, onModalClose } = useSelector<
    IRootState,
    BasisDocumentViewModalInterface
  >((state) => state.modal.modalProps);

  const { document, fetchingDocumentLoading } = useSelector<
    IRootState,
    IProductUsageState
  >((state) => state.productUsage);

  const [isGrouped, setIsGrouped] = useState(false);
  const [decommissioned, setDecommissioned] = useState(0);
  const [wasteDecommissioned, setWasteDecommissioned] = useState(0);
  const [original, setOriginal] = useState(0);

  const columns = useMemo(() => {
    const columnsData = generateColumnData({ docType, isRead: true });
    columnsData.shift();
    return columnsData;
  }, [docType]);

  const onClose = () => {
    if (onModalClose) onModalClose();
    dispatch(features.modal.actions.hideModal());
    dispatch(features.productUsage.actions.clearDocumentData());
  };

  const handleRadioButtonClick = useCallback(() => {
    setIsGrouped((prevState) => !prevState);
  }, []);
  const { application } = useSelector<IRootState, IApplicationState>(
    (state) => state.application
  );

  const { application: officialApplication } = useSelector<
    IRootState,
    IApplicationReviewState
  >((state) => state.officialApplicationReview);

  const getProducts = useCallback(
    (productsList) => {
      if (!productsList) return [];
      const products = productResParser(productsList, docType, true);
      return products;
    },
    [docType]
  );

  const products = useMemo(() => {
    const products = getProducts(document?.products);
    return products;
  }, [document?.products, getProducts]);

  const wasteProducts = useMemo(() => {
    const products = getProducts(document?.wasteProducts);
    return products;
  }, [document?.wasteProducts, getProducts]);

  const childrenProducts = useMemo(() => {
    const children = document?.children?.map((item) => {
      const products = getProducts(item.products);
      const { decommissioned } = calculateTotals(products);

      return {
        ...item,
        products,
        decommissioned,
        docType: docType
      };
    });
    return children;
  }, [document?.children, getProducts]);

  const modalTitle = useMemo(() => {
    if (docType === "transfer") return "Переміщення";
    if (docType === "woodCard") return "Лісорубний квиток";
    return `${isPrimal ? "Первинний " : "Перехідний"}   документ підстави`;
  }, [docType, isPrimal]);

  const getSelectedRows = useCallback((products) => {
    return products.reduce((result, item, currentIndex) => {
      if (item.decommissioned > 0) result[currentIndex] = true;
      return result;
    }, {});
  }, []);

  const applicationUuid = useMemo(() => {
    return application?.uuid ?? officialApplication?.uuid;
  }, [application?.uuid, officialApplication?.uuid]);

  useEffect(() => {
    if (products?.length) {
      const { decommissioned, original } = calculateTotals(products);
      setDecommissioned(decommissioned);
      setOriginal(original);
    }
    if (wasteProducts?.length) {
      const { decommissioned: wasteDecommissioned } =
        calculateTotals(wasteProducts);
      setWasteDecommissioned(wasteDecommissioned);
    }
    if (childrenProducts) {
      let decommissioned = 0;
      let original = 0;
      childrenProducts.forEach((item) => {
        decommissioned = item.decommissioned + decommissioned;
        original = item.quantityOrigin + original;
      });
      setDecommissioned(formatNumber(decommissioned, 5));
      setOriginal(formatNumber(original, 5));
    }
  }, [childrenProducts, wasteProducts, products]);

  useEffect(() => {
    if (applicationUuid && docUuid)
      if (docType === "woodCard") {
        dispatch(
          features.productUsage.actions.fetchUsedWoodCardDocumentRequest({
            params: {
              appUuid: applicationUuid,
              woodCardUuid: docUuid
            },
            onError: (errorsRes) => {
              dispatch(features.modal.actions.hideModal());
              parseResErrors({ errorsRes });
            }
          })
        );
      } else {
        dispatch(
          features.productUsage.actions.fetchUsedDocumentRequest({
            params: {
              applicationUuid,
              uuid: docUuid
            },
            onError: (errorsRes) => {
              dispatch(features.modal.actions.hideModal());
              parseResErrors({ errorsRes });
            }
          })
        );
      }
  }, [applicationUuid, dispatch, docUuid]);

  useEffect(
    () => () => dispatch(features.productUsage.actions.clearDocumentData()),
    []
  );

  const getTitle = useCallback(
    (index) => {
      if (document?.children) {
        const children = document.children[index];

        return (
          <>
            {children.typeName}, {children.series} {children.number},
            {formatDate(children.date).date}
            <br /> Вагон №{children.freightCarNumber}
          </>
        );
      }
    },
    [document?.children]
  );

  const handlePreviousUsageClick = useCallback(() => {
    const chain = isPrimal ? "primary" : "transition";

    if (document?.transferExternalIds) {
      document?.transferExternalIds.forEach((docId) =>
        window.open(
          `/${
            isOfficial ? "official" : "personal"
          }/applications/${applicationUuid}/documents/${chain}/${docType}/${
            docId ?? document?.uuid
          }/previous-usage`,
          "_blank"
        )
      );
    } else
      window.open(
        `/${
          isOfficial ? "official" : "personal"
        }/applications/${applicationUuid}/documents/${chain}/${docType}/${
          docId ?? document?.uuid
        }/previous-usage`,
        "_blank"
      );
  }, [isPrimal, isOfficial, applicationUuid, docType, docId, document?.uuid]);

  return (
    <ModalWrapper className={styles["modal-container"]} onHide={onModalClose}>
      <PreloaderWrapper loading={fetchingDocumentLoading}>
        <div className={styles["primary-document-modal"]}>
          <h2 className={styles["primary-document-modal__title"]}>
            {modalTitle}
          </h2>
          <CreateDocForm
            initialValues={{
              docType: document?.typeName || "Лісорубний квиток",
              series: document?.series,
              number: document?.number,
              date: document?.date || document?.issueDate,
              forestUser: {
                value:
                  document?.sourceOrganizationExternalId ||
                  document?.externalId,
                label:
                  document?.sourceOrganizationName ||
                  document?.forestHouseholdName
              },
              counterparty: {
                value: document?.recipientOrganizationExternalId,
                label: document?.recipientOrganizationName
              },
              forestUserEdrpo:
                document?.sourceOrganizationEdrpou ||
                document?.forestHouseholdEdrpou,
              forestUserLocation:
                document?.sourceOrganizationCountry ||
                document?.forestHouseholdCountry,
              counterpartyEdrpo: document?.recipientOrganizationEdrpou,
              counterpartyLocation: document?.recipientOrganizationCountry
            }}
            isTransfer={docType === "transfer"}
            docType={docType}
            loadedFiles={document?.files ?? []}
            additionalDocuments={document?.additionalDocuments}
            isPrimal={isPrimal}
            readOnly
          />
          {childrenProducts?.length > 0 && (
            <div className={styles["primary-document-modal__general-actions"]}>
              <CommonButton
                className={styles["primary-document-modal__controls-button"]}
                label="Попереднє використання"
                outlined={true}
                image={EyeIcon}
                onClick={handlePreviousUsageClick}
              />
              <div
                className={cn(
                  styles["primary-document-modal__switch-wrapper"],
                  styles["primary-document-modal__main-info-single-small"]
                )}
              >
                <SwitchButton
                  active={isGrouped}
                  onChange={handleRadioButtonClick}
                />
                <h4 className={styles["primary-document-modal__switch-title"]}>
                  Згрупувати
                </h4>
              </div>
            </div>
          )}
          {products?.length > 0 && (
            <ChildrenProducts
              data={{
                ...document,
                products,
                wasteProducts,
                decommissioned,
                wasteDecommissioned,
                docType: docType,
                wasteQuantityPercent: document?.wasteQuantityPercent
              }}
              columns={columns}
              selectedRows={getSelectedRows(products)}
              selectedWasteRows={getSelectedRows(wasteProducts)}
              geometricDimension={document.products[0].geometricDimension}
              wasteGeometricDimension={
                document?.wasteProducts[0]?.geometricDimension
              }
              handlePreviousUsageClick={handlePreviousUsageClick}
            />
          )}
          {childrenProducts?.length > 0 &&
            childrenProducts.map((children, index) => (
              <ChildrenProducts
                title={getTitle(index)}
                data={children}
                columns={columns}
                selectedRows={getSelectedRows(children.products)}
                geometricDimension={
                  document.children[index]?.products[0].geometricDimension
                }
                isGrouped={isGrouped}
                isMultiple
              />
            ))}
          {childrenProducts && (
            <div className={styles["primary-document-modal__total-block"]}>
              <h4
                className={styles["primary-document-modal__total-block-header"]}
              >
                Всього по ЗН
              </h4>
              <div
                className={
                  styles["primary-document-modal__total-block-unit-wrapper"]
                }
              >
                <h4
                  className={
                    styles["primary-document-modal__total-block-unit-label"]
                  }
                >
                  Оригінал
                </h4>
                <h4
                  className={
                    styles["primary-document-modal__total-block-unit-text"]
                  }
                >
                  {original}
                </h4>
              </div>
              <div
                className={
                  styles["primary-document-modal__total-block-unit-wrapper"]
                }
              >
                <h4
                  className={
                    styles["primary-document-modal__total-block-unit-label"]
                  }
                >
                  Списано
                </h4>
                <h4
                  className={
                    styles["primary-document-modal__total-block-unit-text"]
                  }
                >
                  {decommissioned}
                </h4>
              </div>
            </div>
          )}

          <div className={styles["primary-document-modal__general-controls"]}>
            <CommonButton label="Закрити" outlined onClick={onClose} />
          </div>
        </div>
      </PreloaderWrapper>
    </ModalWrapper>
  );
};
export default BasisDocViewModal;
