import { Form, FormikProvider } from "formik";
import { CommonButton } from "gov-ua-ui";

import { formatDate } from "helpers";

import DocumentForUse from "components/certificateComponents/viewCertificateComponents/DocumentForUse/DocumentForUse";
import ZSNDocView from "components/railDocsComponents/ZSNDocView/ZSNDocView";

import DodPDFIcon from "assets/images/icons/doc_preview.svg";
import EdocIcon from "assets/images/icons/e_doc.svg";

import styles from "../../search-basis-doc-page.module.scss";

const SearchResult = ({
  formikUnion,
  foundDocs,
  getDocTypeAlias,
  onCreateZNDocumentClick,
  modifiedSearch,
  parentId,
  counterparty
}) => {
  return (
    <FormikProvider value={formikUnion}>
      <Form
        className={
          styles["create-primary-document__document-for-use-container"]
        }
      >
        {foundDocs.map((item, i) => {
          return getDocTypeAlias(item.typeName) === "zsn" ? (
            <ZSNDocView
              key={i}
              image={DodPDFIcon}
              type={item.typeName}
              wagon={item.freightCarNumber}
              serial={item.series}
              number={item.number}
              date={formatDate(item.date).date}
              selectable
              selectDisabledText={
                !item?.counterParty
                  ? "Немає контрагента, зверніться до адміністратора"
                  : item?.counterParty !== counterparty &&
                    counterparty.length > 0 &&
                    formikUnion?.values?.filter((item) => item).length !== 0
                  ? "Контрагент не співпадає з попередньо вибраними ЗСН"
                  : undefined
              }
              name={i.toString()}
              checked={formikUnion.values[i]}
              customFields={[
                {
                  title: "Кількість оригінал",
                  text: item?.quantity ?? item?.quantityOrigin
                },
                {
                  title: "Кількість доступно",
                  text: item.quantityAvailable
                },
                {
                  title: "Лісокористувач / Продавець",
                  text: item?.forestUserName
                },
                {
                  title: "Контрагент / Покупець",
                  text: item?.counterParty
                }
              ]}
              isEOD
            />
          ) : (
            <DocumentForUse
              key={`document + ${i}`}
              documentId={item?.id?.toString()}
              docImg={EdocIcon}
              loadedFiles={item?.files}
              additionalDocuments={item?.additionalDocuments}
              type={getDocTypeAlias(item.typeName)}
              zsnList={item?.children}
              typeName={item.typeName}
              series={item.series}
              number={item.number}
              date={formatDate(item.date).date}
              seller={{
                sellerName: item.forestUserName,
                sellerExternalId: item.forestUserExternalId
              }}
              shopper={{
                shopperName: item?.counterParty,
                shopperExternalId: item?.counterPartyExternalId
              }}
              initialCount={item.quantity}
              availableCount={item.quantityAvailable}
              wasteQuantity={item.wasteQuantity}
              wasteQuantityAvailable={item.wasteQuantityAvailable}
              wasteQuantityPercent={item.wasteQuantityPercent}
              isPrimal={!parentId}
            />
          );
        })}
        {foundDocs.find((item) => getDocTypeAlias(item.typeName) === "zsn") && (
          <div className={styles["create-primary-document__btns"]}>
            <CommonButton
              type="submit"
              label={"Об’єднати обрані"}
              disabled={
                formikUnion?.values?.filter((item) => item).length === 0
              }
            />
            <CommonButton
              label="Створити новий"
              onClick={onCreateZNDocumentClick}
              disabled={modifiedSearch}
            />
          </div>
        )}
      </Form>
    </FormikProvider>
  );
};

export default SearchResult;
