import cn from "classnames";
import { Form, FormikProvider, useFormik } from "formik";
import {
  CommonButton,
  FormField,
  Title,
  VisualUploadMultipleFiles
} from "gov-ua-ui";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import features from "features";
import { parseFilesErrors, parseResErrors } from "helpers";
import { IRootState } from "reducer";
import { ICertificateAnnulRequestState } from "../ducks";

import Certificate from "components/Certificate/Certificate";
import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";
import ScrollToFieldError from "components/ScrollToFieldError/ScrollToFieldError";
import EDocsSection from "components/certificateComponents/viewCertificateComponents/EDocsSection/EDocsSection";
import SectionDelimiter from "components/certificateComponents/viewCertificateComponents/SectionDelimiter/SectionDelimiter";
import CertificatesLayout from "components/layouts/CertificatesLayout/CertificatesLayout";

import EDocIcon from "assets/images/icons/e_doc.svg";
import EDocApplicationIcon from "assets/images/icons/e_doc_application.svg";

import styles from "./official-certificate-annul-request-page.module.scss";

const OfficialCertificateAnnulRequestPage = () => {
  const dispatch = useDispatch();
  const { applicationId } = useParams();

  const { fetchingApplicationLoading, application } = useSelector<
    IRootState,
    ICertificateAnnulRequestState
  >((state) => state.officialCertificateAnnulRequest);

  const navigate = useNavigate();

  const navigationLinks = [
    {
      text: "Загальна інформація",
      to: "#primary-info",
      anchor: true
    },
    {
      text: "Підстава анулювання",
      to: "#grounds-for-annull",
      anchor: true
    },
    {
      text: "",
      to: "#e-order",
      anchor: true,
      customContent: (
        <>
          єСертифікат
          <img
            src={EDocIcon}
            alt="єСертифікат"
            className={styles["certificates-nav-btn__img"]}
          />
        </>
      ),
      customStyles: styles["certificates-nav-btn"]
    },
    {
      text: "",
      to: "#e-order",
      anchor: true,
      customContent: (
        <>
          еЗаява
          <img
            src={EDocApplicationIcon}
            alt="еЗаява"
            className={styles["certificates-nav-btn__img"]}
          />
        </>
      ),
      customStyles: styles["certificates-nav-btn"]
    }
  ];

  const onFormSubmit = (values: any, { setFieldError }) => {
    const fields = {
      annulmentBasisDescription: values.reason.toString()
    };

    dispatch(
      features.modal.actions.showModal({
        modalType: "PRELOADER",
        modalProps: {
          title: "Опрацювання запиту",
          loading: true
        }
      })
    );
    dispatch(
      features.officialCertificateAnnulRequest.actions.submitEAnnulmentDataRequest(
        {
          params: {
            applicationUuid: applicationId
          },
          fields: {
            ...fields
          },
          onSuccess: () => {
            dispatch(features.modal.actions.hideModal());
            toastr.success(
              "Успіх",
              "Заявка на ануляцію успішно подана, чекайте на повідомлення."
            );
            navigate("/official-archival");
          },
          onError: (errorsRes) => {
            parseResErrors({
              setFieldError,
              errorsRes,
              fieldsAliases: { annulmentBasisDescription: "reason" },
              fields: values
            });
            dispatch(features.modal.actions.hideModal());
          }
        }
      )
    );
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      reason: ""
    },
    validationSchema: Yup.object().shape({
      reason: Yup.string().required("Поле обов’язкове")
    }),
    enableReinitialize: true,
    onSubmit: onFormSubmit
  });

  const onDocumentView = useCallback(
    (
      file: { name: string; filePath: any; uuid: string }
      // withDownload?: boolean
    ) => {
      window.open(file.filePath, "_blank");

      // dispatch(
      //   features.modal.actions.showModal({
      //     modalType: getDocumentViewType(file.name),
      //     modalProps: { url: file.filePath, uuid: file.uuid, withDownload }
      //   })
      // );
    },
    [dispatch]
  );

  const onLoadReasonFiles = (acceptedFiles, rejectedFiles, replaceFile) => {
    if (acceptedFiles) {
      acceptedFiles.forEach((acceptedFile) => {
        dispatch(
          features.officialCertificateAnnulRequest.actions.loadReasonFileRequest(
            {
              params: {
                uuid: applicationId,
                replaceUuid: replaceFile?.uuid
              },
              fields: {
                fileType: "annulment_application_documents_copy",
                file: Object.assign(acceptedFile, {
                  alias: "annulmentApplicationDocumentsCopy"
                })
              }
            }
          )
        );
      });
    }

    parseFilesErrors(rejectedFiles);
  };

  const onDeleteReasonFile = async (file) => {
    await new Promise((resolve) => {
      dispatch(
        features.officialCertificateAnnulRequest.actions.deleteReasonFileRequest(
          {
            params: {
              uuid: file.uuid,
              applicationUuid: applicationId
            },
            type: file.type,
            name: file.name,
            onSuccess: () => resolve(() => true),
            onError: () => resolve(() => true)
          }
        )
      );

      if (file.cancel) {
        file.cancel();
      }
    });
  };

  const getEDocs = useMemo(() => {
    const docs = [];
    if (application?.files?.eApplication.length)
      docs.push({
        docImg: EDocIcon,
        docTitle: "еЗаява",
        onClick: () => onDocumentView(application?.files?.eApplication[0])
      });
    if (application?.files?.eCertificate.length)
      docs.push({
        docImg: EDocApplicationIcon,
        docTitle: "єСертифікат",
        onClick: () => onDocumentView(application?.files?.eCertificate[0])
      });
    return docs;
  }, [application, onDocumentView]);

  useEffect(() => {
    if (applicationId) {
      dispatch(
        features.officialCertificateAnnulRequest.actions.fetchApplicationRequest(
          {
            params: { uuid: applicationId }
          }
        )
      );
    }

    dispatch(
      features.modal.actions.hideModal({
        modalType: "PRELOADER"
      })
    );

    return () => {
      dispatch(features.application?.actions.clearApplication());
    };
  }, [applicationId, dispatch]);

  return (
    <CertificatesLayout
      navLinks={navigationLinks}
      title={
        "Заявка на анулювання сертифікату про походження лісоматеріалів та виготовлених з них пиломатеріалів в електронній формі"
      }
      isOfficial
    >
      <PreloaderWrapper loading={fetchingApplicationLoading} withModal>
        <Certificate data={application} id="primary-info" />
        <SectionDelimiter />

        <div id="e-order">
          <EDocsSection docs={getEDocs} />
        </div>

        <SectionDelimiter />

        <div
          className={styles["grounds-for-cancellation"]}
          id="grounds-for-annull"
        >
          <Title
            size={24}
            className={styles["grounds-for-cancellation__title"]}
          >
            Підстава анулювання
          </Title>
          <FormikProvider value={formik}>
            <Form className={cn(styles["personal-cabinet-page"])}>
              <FormField
                name="reason"
                placeholder="Опишіть причину анулювання"
                as="textarea"
                className={cn(
                  styles["search-container__input"],
                  "textarea-field"
                )}
              />
              <h4 className={styles["grounds-for-cancellation__upload-title"]}>
                Копія документів (необов’язково)
              </h4>
              <VisualUploadMultipleFiles
                title={"Завантажити скан-копію документів"}
                accept={{
                  "application/pdf": [".pdf"],
                  "image/jpeg": [".jpg"]
                }}
                containerClassName={
                  styles["grounds-for-cancellation__upload-container"]
                }
                contentClassName={
                  styles["grounds-for-cancellation__upload-content"]
                }
                loadedFiles={
                  application?.files?.annulmentApplicationDocumentsCopy
                }
                onDelete={onDeleteReasonFile}
                onDocumentView={onDocumentView}
                onLoad={onLoadReasonFiles}
              />
              <CommonButton label="Перевірити та анулювати" type="submit" />
            </Form>
            <ScrollToFieldError />
          </FormikProvider>
        </div>
      </PreloaderWrapper>
    </CertificatesLayout>
  );
};

export default OfficialCertificateAnnulRequestPage;
