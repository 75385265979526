import cn from "classnames";
import { Form, FormikProvider, useFormik } from "formik";
import {
  CommonButton,
  DatePicker,
  FormField,
  Select,
  VisualUploadMultipleFiles
} from "gov-ua-ui";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import features from "features";
import {
  calculateTotals,
  parseFilesErrors,
  parseResErrors,
  productValidationSchemaReq,
  productsReqParser,
  tableRowReq
} from "helpers";
import { IRootState } from "reducer";
import { IDatasetState } from "scenes/Dataset/ducks";
import { ISubjectDocs } from "scenes/subject/documents/ducks";
import { BASIS_DOC_TYPE } from "scenes/subject/documents/interfaces/interfaces";

import ScrollToFieldError from "components/ScrollToFieldError/ScrollToFieldError";
import EditableProductsTable from "components/certificateComponents/createCertificateComponents/DefineProducts/EditableProductsTable";
import SavedZSN from "./components/SavedZSN/SavedZSN";

import styles from "./create-zsn.module.scss";

interface FormikInterface {
  freightCarNumber: string;
  series: string;
  number: string;
  date: Date;
  loadedDoc: string;
  geometricSizes: string;
  standard: {
    value: string;
    label: string;
  };
  size: {
    value: string;
    label: string;
  };
  products: any[];
  count: number;
  amount: number | string;
  amountPDV: number | string;
}

interface CreateZSNInterface {
  title: string;
  onUpdateSummary: (data: { uuid: string; values }) => void;
  zsn: any;
  onRequestEdit: (isActive: boolean) => void;
  isEditingActive: boolean;
}

const formatChars = {
  "9": "[0-9]",
  a: "[A-Za-z]",
  "*": "[A-Za-z0-9]",
  я: "[А-Яа-яІіЇїЄєA-Za-z]",
  Я: "[А-Яа-яІіЇїЄєA-Za-z0-9]"
};

const numberFieldMask = "99999999";
const seriesFieldMask = "яяя";

const CreateZSN = ({
  title,
  onUpdateSummary,
  zsn,
  onRequestEdit,
  isEditingActive
}: CreateZSNInterface) => {
  const dispatch = useDispatch();
  const { documentId } = useParams();

  const { document } = useSelector<IRootState, ISubjectDocs>(
    (state) => state.subjectDocs
  );

  const { sizeList, standardList, speciesList, sortList } = useSelector<
    IRootState,
    IDatasetState
  >((state) => state.dataset);

  const [maxDate] = useState(new Date(document.date));
  const [submited, setLockedForm] = useState(false);
  const [initSavedProducts, setInitSavedProducts] = useState(false);

  const handleFormSubmit = (values: FormikInterface, { setFieldError }) => {
    if (
      !zsn.files.length ||
      zsn.files.find(
        (file) => file.progress !== 100 && file.progress !== undefined
      )
    ) {
      toastr.error("Помилка", "Завантажте всі файли");
      return;
    }

    const { freightCarNumber, number, series, date, amount, count, amountPDV } =
      values;
    const { sourceOrganizationExternalId, recipientOrganizationExternalId } =
      document;

    const geometricDimensionUuid = values.size.value;

    const products = productsReqParser(values.products, {
      productStandardUuid: values.standard.value,
      geometricDimensionUuid
    });
    dispatch(
      features.modal.actions.showModal({
        modalType: "PRELOADER",
        modalProps: {
          title: "Опрацювання запиту",
          loading: true
        }
      })
    );
    dispatch(
      features.subjectDocs.actions.updateDocRequest({
        params: {
          basisDocumentType: "zsn",
          basisDocumentUuid: zsn.uuid
        },
        fields: {
          products,
          freightCarNumber,
          series,
          number,
          date,
          sourceOrganizationExternalId,
          recipientOrganizationExternalId
        },
        onSuccess: () => {
          dispatch(
            features.subjectDocs.actions.unionDocRequest({
              fields: {
                parentUuid: documentId,
                childrenUuids: document.children.map((item) => item.uuid)
              },
              onSuccess: () => {
                setLockedForm(true);
                onRequestEdit(false);
                onUpdateSummary({
                  uuid: zsn.uuid,
                  values: { amount, quantity: count, amountPDV }
                });
                dispatch(features.modal.actions.hideModal());
              },
              onError: (e) => {
                parseResErrors({
                  errorsRes: e
                });
                dispatch(features.modal.actions.hideModal());
              }
            })
          );
        },
        onError: (e) => {
          dispatch(features.modal.actions.hideModal());

          parseResErrors({
            setFieldError: setFieldError,
            errorsRes: e,
            fields: values,
            fieldsAliases: {
              quantityMeasurementUnitUuid: "unit",
              productExternalId: "productType",
              woodSpecieUuids: "wood",
              woodGradeUuids: "sort",
              accountingUuid: "accounting"
            }
          });
        }
      })
    );
  };
  const formik = useFormik<FormikInterface>({
    validateOnChange: true,
    initialValues: {
      freightCarNumber: "",
      series: "",
      number: "",
      date: null,
      loadedDoc: "",
      geometricSizes: "",
      standard: {
        value: "",
        label: ""
      },
      size: {
        value: "",
        label: ""
      },
      count: 0,
      amount: "0.00",
      amountPDV: "0.00",
      products: [tableRowReq]
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      number: Yup.string().required("Введіть номер"),
      freightCarNumber: Yup.string()
        .required("Введіть номер")
        .max(8, "Максимальна кількість 8 цифер"),
      date: Yup.date().required("Введіть дату"),
      products: productValidationSchemaReq(Yup)
    }),
    onSubmit: handleFormSubmit
  });

  const handleChangeZSNClick = useCallback(() => {
    if (!isEditingActive) {
      setLockedForm(false);
      onRequestEdit(true);
    } else {
      toastr.error(
        "Помилка",
        "Потрібно спочатку завершити редагування попередньої ЗСН"
      );
    }
  }, [isEditingActive, onRequestEdit]);

  const handleDatePickerChange = (date: Date) => {
    formik.setFieldValue("date", date);
  };

  const handleChangeStandardClick = (value) => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "CONFIRM_ACTION",
        modalProps: {
          notificationText:
            "Якщо ви змінете стандарт продукції, всі заповнені поля в стовпчиках продукція, порода, сорт та УКТЗЕД видаляться. Ці поля потрібно буде заповнити повторно. Все одно змінити?",
          acceptLabel: "Так, продовжити",
          onAccept: () => {
            const updatedProducts = formik.values["products"].map((product) => {
              return {
                ...product,
                productType: { label: "", value: "" },
                wood: [],
                sort: [],
                uktzed: ""
              };
            });
            formik.setFieldValue("products", updatedProducts);
            formik.setFieldValue("standard", value);
          }
        }
      })
    );
  };

  const handleSelectChange = (value, name) => {
    if (
      name === "standard" &&
      value.value !== formik.values["standard"].value
    ) {
      const isNeedConfirm = formik.values["products"].find((product) => {
        if (
          product["productType"]?.value &&
          product["productType"]?.label?.length
        )
          return true;
        if (product["wood"].length) return true;
        if (product["sort"].length) return true;
        if (product["uktzed"].length) return true;

        return false;
      });
      if (isNeedConfirm) handleChangeStandardClick(value);
      else formik.setFieldValue(name, value);
    } else {
      formik.setFieldValue(name, value);
    }
  };

  const onDeleteFile = async (file) => {
    await new Promise((resolve) => {
      dispatch(
        features.subjectDocs.actions.deleteDocFileRequest({
          params: {
            documentUuid: zsn.uuid,
            fileUuid: file.uuid
          },
          type: file.type,
          name: file.name,
          onSuccess: () => resolve(() => true),
          onError: () => resolve(() => true)
        })
      );
      if (file.cancel) {
        file.cancel();
      }
    });
  };

  const onDocumentView = (file) => {
    window.open(file.filePath, "_blank");
  };

  const onLoadFiles = (acceptedFiles, rejectedFiles, replaceFile) => {
    if (acceptedFiles)
      acceptedFiles.forEach((file) => {
        dispatch(
          features.subjectDocs.actions.uploadDocFileRequest({
            params: {
              basisDocumentUuid: zsn.uuid,
              replaceUuid: replaceFile?.uuid
            },
            fields: {
              fileType: BASIS_DOC_TYPE.ZSN_SCANCOPY,
              file: Object.assign(file, {
                alias: BASIS_DOC_TYPE.ZSN_SCANCOPY
              })
            }
          })
        );
      });

    parseFilesErrors(rejectedFiles);
  };

  const initFields = useCallback(() => {
    if (standardList.length && speciesList.length && sortList.length) {
      const products = zsn.products.map((product) => {
        const standard = standardList.find(
          (item) => item.value === product.productStandardUuid
        );

        const wood = product.woodSpecieUuids?.map((uuid) => {
          const foundWood = speciesList.find((item) => item.value === uuid);
          return foundWood;
        });

        const sort = product.woodGradeUuids?.map((uuid) => {
          const foundSort = sortList.find((item) => item.value === uuid);
          return foundSort;
        });

        const productRow = {
          productType: {
            value: product.productExternalId,
            label: product.productName
          },
          standard: standard,
          clarification: product.note || "",
          wood: wood,
          sort: sort,
          diameter: product.diameter || "",
          diameterGroup: product.diameterGroup || "",
          thickness: product.height || "",
          width: product.width || "",
          length: product.length || "",
          count: product.quantityOrigin,
          unit: {
            value: product.quantityMeasurementUnitUuid,
            label: product.quantityMeasurementUnitName
          },
          price: product.price,
          amount: product.totalPrice,
          uktzed: product.uktzed,
          accounting: {
            value: product.accountingUuid,
            label: product.accounting
          }
        };

        return productRow;
      });

      const { amount, quantity, amountPDV } = calculateTotals(products);

      formik.setFieldValue("number", zsn.number);
      formik.setFieldValue("series", zsn.series);
      formik.setFieldValue("freightCarNumber", zsn.freightCarNumber);
      formik.setFieldValue("date", new Date(zsn.date));
      formik.setFieldValue("products", products);
      formik.setFieldValue("count", quantity);
      formik.setFieldValue("amount", amount);
      formik.setFieldValue("amountPDV", amountPDV);

      setLockedForm(true);

      setInitSavedProducts(true);

      onUpdateSummary({
        uuid: zsn.uuid,
        values: { amount, quantity, amountPDV }
      });
    }
  }, [zsn, onUpdateSummary, standardList, speciesList, sortList]);

  const cancelEdit = useCallback(() => {
    if (zsn.products?.length > 0) initFields();
    else dispatch(features.subjectDocs.actions.deleteChildDoc(zsn.uuid));
    onRequestEdit(false);
  }, [dispatch, onRequestEdit, zsn, initFields]);

  useEffect(() => {
    if (
      standardList &&
      standardList.length &&
      !formik.values["standard"].value
    ) {
      formik.setFieldValue("standard", standardList[0]);
    }
    if (sizeList && sizeList.length && !formik.values["size"].value) {
      formik.setFieldValue("size", sizeList[0]);
    }
  }, [formik.values["standard"].value, standardList, sizeList]);

  useEffect(() => {
    if (
      zsn &&
      zsn?.products?.length &&
      standardList.length &&
      speciesList.length &&
      sortList.length &&
      !initSavedProducts
    ) {
      initFields();
    }
  }, [initFields, initSavedProducts, sortList, speciesList, standardList, zsn]);

  return (
    <section
      className={cn(styles["create-zsn"], {
        [styles["create-zsn_submitted"]]: submited
      })}
    >
      {!submited ? (
        <>
          <h3 className={styles["create-zsn__title"]}>{title}</h3>
          <FormikProvider value={formik}>
            <Form className={styles["create-zsn__form"]}>
              <div className={styles["create-zsn__form-first-part"]}>
                <div className={styles["create-zsn__form-text-fields"]}>
                  <div
                    className={cn(
                      styles["create-zsn__form-row"],
                      styles["create-zsn__form-row_first"]
                    )}
                  >
                    <div className={styles["create-zsn__form-text-item"]}>
                      <p>Тип</p>
                      <p>ЗСН</p>
                    </div>
                    <FormField
                      name="freightCarNumber"
                      placeholder="Вагон"
                      mask={numberFieldMask}
                      maskChar={""}
                      maskFormatChars={formatChars}
                    />
                  </div>
                  <div
                    className={cn(
                      styles["create-zsn__form-row"],
                      styles["create-zsn__form-row_second"]
                    )}
                  >
                    <FormField
                      name="series"
                      placeholder="Серія"
                      mask={seriesFieldMask}
                      maskChar={""}
                      maskFormatChars={formatChars}
                    />
                    <FormField
                      name="number"
                      placeholder="Номер"
                      onBlur={(val) =>
                        formik.setFieldValue("number", val.target.value.trim())
                      }
                    />
                    <DatePicker
                      name="date"
                      date={formik.values["date"]}
                      onChange={handleDatePickerChange}
                      placeholder="Дата"
                      withFormik
                      maxDate={maxDate}
                    />
                  </div>
                </div>
                <VisualUploadMultipleFiles
                  title="Завантажити скан-копію"
                  accept={{
                    "application/pdf": [".pdf"],
                    "image/jpeg": [".jpg"]
                  }}
                  loadedFiles={zsn.files}
                  onDelete={onDeleteFile}
                  onDocumentView={onDocumentView}
                  onLoad={onLoadFiles}
                  containerClassName={styles["create-doc__form-file-loader"]}
                />
              </div>
              <div
                className={styles["create-document-page__table-form-selects"]}
              >
                <Select
                  name="size"
                  value={formik.values["size"]}
                  options={sizeList}
                  placeholder="Геометричні розміри"
                  onSelectChange={handleSelectChange}
                />
                <Select
                  name="standard"
                  value={formik.values["standard"]}
                  options={standardList}
                  placeholder="Стандарт"
                  onSelectChange={handleSelectChange}
                  blurInputOnSelect={true}
                />
              </div>

              <div className={styles["create-document-page__table-form-table"]}>
                <EditableProductsTable
                  formik={formik}
                  tableRow={tableRowReq}
                  enabledColumns={[
                    ...Object.keys(tableRowReq),
                    "accounting",
                    "actions"
                  ]}
                  withPDV
                />
              </div>
              <div className={styles["create-zsn__form-buttons"]}>
                <CommonButton
                  label="Скасувати"
                  disabled={
                    document.children.length === 1 && zsn.products.length === 0
                  }
                  outlined
                  onClick={cancelEdit}
                  className={styles["create-zsn__form-button"]}
                />
                <CommonButton
                  type="submit"
                  label="Зберегти"
                  disabled={submited}
                  className={styles["create-zsn__form-button"]}
                />
              </div>
            </Form>
            <ScrollToFieldError />
          </FormikProvider>
        </>
      ) : (
        <SavedZSN
          zsn={zsn}
          onDocumentView={onDocumentView}
          handleChangeZSNClick={handleChangeZSNClick}
          formik={formik}
        />
      )}
    </section>
  );
};

export default CreateZSN;
