import { createColumnHelper } from "@tanstack/react-table";
import { CommonButton, VisualUploadMultipleFiles } from "gov-ua-ui";
import isEmpty from "lodash/isEmpty";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate, useParams } from "react-router-dom";

import features from "features";
import {
  calculateTotals,
  formatDate,
  generateNavButton,
  iGenerateNavButton,
  parseResErrors
} from "helpers";
import { IRootState } from "reducer";
import { IDatasetState } from "scenes/Dataset/ducks";
import { IApplicationReviewState } from "scenes/official/certificates/OfficialApplicationReviewPage/ducks";

import Certificate from "components/Certificate/Certificate";
import NavigationHeadSubtitle from "components/NavigationHeadSubtitle/NavigationHeadSubtitle";
import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";
import Section from "components/Section/Section";
import SectionDoc from "components/Section/SectionDoc/SectionDoc";
import SectionText from "components/Section/SectionText/SectionText";
import BasisIssuingCertificate from "components/certificateComponents/createCertificateComponents/BasisIssuingCertificate/BasisIssuingCertificate";
import BasisIssuingCertificateWoodCards from "components/certificateComponents/createCertificateComponents/BasisIssuingCertificateWoodCards/BasisIssuingCertificateWoodCards";
import Applicant from "components/certificateComponents/viewCertificateComponents/Applicant/Applicant";
import EDocsSection from "components/certificateComponents/viewCertificateComponents/EDocsSection/EDocsSection";
import GroundsForCancellation from "components/certificateComponents/viewCertificateComponents/GroundsForCancellation/GroundsForCancellation";
import Invoice from "components/certificateComponents/viewCertificateComponents/Invoice/Invoice";
import OtherDocs from "components/certificateComponents/viewCertificateComponents/OtherDocs/OtherDocs";
import Products from "components/certificateComponents/viewCertificateComponents/Products/Products";
import SectionDelimiter from "components/certificateComponents/viewCertificateComponents/SectionDelimiter/SectionDelimiter";
import TrustedDoc from "components/certificateComponents/viewCertificateComponents/TrustedDoc/TrustedDoc";
import CertificatesLayout from "components/layouts/CertificatesLayout/CertificatesLayout";
import AnnulmentDecline from "./components/AnnulmentDecline";

import eDocIcon from "assets/images/icons/e_doc.svg";
import eDocAnnulAltIcon from "assets/images/icons/e_doc_annul_alt.svg";
import eDocApplicationIcon from "assets/images/icons/e_doc_application.svg";
import eDocDecline from "assets/images/icons/e_doc_refusal.svg";

import styles from "./official-application-review-page.module.scss";

interface ProductsTableData {
  standard: string;
  product: string;
  description: string;
  bread: string;
  sort: number;
  diameter: number;
  thickness: number;
  width: number;
  length: number;
  count: number;
  unit: string;
  price: number;
  amount: number;
  uktzed: number;
}

const OfficialCertificateReviewPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { applicationId, applicationType } = useParams<{
    applicationId?: string;
    applicationType?: string;
  }>();

  const [productsTotalAmount, setProductsTotalAmount] = useState<number>(null);
  const [isReadonly, setIsReadonly] = useState<boolean>(false);
  const [productsTotalQuantity, setProductsTotalQuantity] =
    useState<number>(null);
  const [invoiceData, setInvoiceData] = useState({
    buyer: "",
    buyerCountry: "",
    consignee: "",
    consigneeCountry: ""
  });

  const {
    fetchingApplicationLoading,
    addingRemarkLoading,
    application,
    fetchingApplicationCertificateError
  } = useSelector<IRootState, IApplicationReviewState>(
    (state) => state.officialApplicationReview
  );
  const { standardList, unitList, speciesList, sortList } = useSelector<
    IRootState,
    IDatasetState
  >((state) => state.dataset);

  const columnHelper = createColumnHelper<ProductsTableData>();

  const isFiles = useCallback(
    (alias: string, onlySign?: boolean) =>
      application?.files[alias]?.length > 0 &&
      (onlySign ? !!application?.files[alias][0].signedPath : true),
    [application?.files]
  );
  const navButton = useCallback(
    ({ to, title, icon, count }: iGenerateNavButton) => {
      return generateNavButton({
        to,
        title,
        icon,
        count,
        imgClass: styles["certificates-nav-btn__img"],
        btnClass: styles["certificates-nav-btn"],
        indicatorClass: styles["certificates-nav-btn__round-indicator"],
        btnIndicatorClass: styles["certificates-nav-btn-indicator"]
      });
    },
    []
  );
  const onBack = useCallback(() => {
    let route = "/official-in-progress";
    switch (applicationType) {
      case "archived":
        route = "/official-archival";
        break;
      case "annulled":
        route = "/official-annul/annulled";
        break;

      default:
        route = "/official-in-progress";
        break;
    }
    navigate(route);
  }, [applicationType, navigate]);

  const onDocumentView = (file) => {
    window.open(file.filePath, "_blank");
  };
  const onDecision = (isPositive: boolean) => {
    if (application?.type === "annulment") {
      if (!isPositive) {
        dispatch(
          features.modal.actions.showModal({
            modalType: "CONFIRM_ACTION",
            modalProps: {
              details: <AnnulmentDecline />,
              hideControls: true
            }
          })
        );
      } else {
        dispatch(
          features.modal.actions.showModal({
            modalType: "CONFIRM_ACTION",
            modalProps: {
              title:
                "Бажаєте перевірити митне оформлення за сертифікатом та анулювати",
              onAccept: () => {
                dispatch(
                  features.modal.actions.showModal({
                    modalType: "PRELOADER",
                    modalProps: {
                      title: "Опрацювання запиту",
                      loading: true
                    }
                  })
                );
                dispatch(
                  features.officialApplicationReview.actions.acceptCertificateAnnulDecisionRequest(
                    {
                      params: {
                        uuid: applicationId
                      },
                      fields: {
                        decision: "annulment"
                      },
                      onSuccess: () => {
                        dispatch(features.modal.actions.hideModal());
                        toastr.success(
                          "Успіх",
                          "Заявка на ануляцію сертифіката успішно подана, чекайте на повідомлення."
                        );
                        navigate("/official-main");
                      },
                      onError: (errorsRes) => {
                        parseResErrors({ errorsRes });
                        dispatch(features.modal.actions.hideModal());
                      }
                    }
                  )
                );
              },
              acceptLabel: "Так",
              declineLabel: "Ні"
            }
          })
        );
      }
    } else {
      dispatch(
        features.modal.actions.showModal({
          modalType: "PRELOADER",
          modalProps: {
            title: `Створюється ${isPositive ? "єСертифікат" : "єВідмова"}`,
            loading: true
          }
        })
      );
      dispatch(
        features.officialApplicationReview.actions.fetchApplicationCertificateRequest(
          {
            params: { uuid: applicationId, isPositive: isPositive },
            onSuccess: () => {
              dispatch(
                features.modal.actions.hideModal({
                  modalType: "PRELOADER"
                })
              );
              isPositive
                ? navigate("e-certificate-confirmation")
                : navigate("e-certificate-deviation");
            },
            onError: () => {
              dispatch(
                features.modal.actions.hideModal({
                  modalType: "PRELOADER"
                })
              );
            }
          }
        )
      );
    }
  };

  const productColumns = useMemo(
    () => [
      columnHelper.accessor("standard", {
        cell: (info: any) => info.getValue(),
        header: () => "Стандарт"
      }),
      columnHelper.accessor("product", {
        cell: (info: any) => info.getValue(),
        header: () => "Продукція"
      }),
      columnHelper.accessor("description", {
        cell: (info: any) => info.getValue(),
        header: () => "Уточнення"
      }),
      columnHelper.accessor("bread", {
        cell: (info: any) => info.getValue(),
        header: () => "Порода"
      }),
      columnHelper.accessor("sort", {
        cell: (info: any) => info.getValue(),
        header: () => "Сорт"
      }),
      columnHelper.accessor("diameter", {
        cell: (info: any) => info.getValue(),
        header: () => "Діаметр"
      }),
      columnHelper.accessor("thickness", {
        cell: (info: any) => info.getValue(),
        header: () => "Товщина"
      }),
      columnHelper.accessor("width", {
        cell: (info: any) => info.getValue(),
        header: () => "Ширина"
      }),
      columnHelper.accessor("length", {
        cell: (info: any) => info.getValue(),
        header: () => "Довжина"
      }),
      columnHelper.accessor("count", {
        cell: (info: any) => info.getValue(),
        header: () => "Кількість"
      }),
      columnHelper.accessor("unit", {
        cell: (info: any) => info.getValue(),
        header: () => "Од. вимір."
      }),
      columnHelper.accessor("price", {
        cell: (info: any) => info.getValue(),
        header: () => "Ціна"
      }),
      columnHelper.accessor("amount", {
        cell: (info: any) => info.getValue(),
        header: () => "Вартість"
      }),
      columnHelper.accessor("uktzed", {
        cell: (info: any) => info.getValue(),
        header: () => "УКТЗЕД"
      })
    ],
    [columnHelper]
  );
  const navigationLinks = useMemo(() => {
    const navigationLinksList = [
      {
        text: "Загальна інформація",
        to: "#application-type",
        anchor: true
      }
    ];
    if (application?.basisDocuments || application?.woodCards)
      navigationLinksList.push({
        text: "Підстава видачі сертифіката",
        to: "#basic-issuing",
        anchor: true
      });

    if (isFiles("additional") && application?.type !== "annulment")
      navigationLinksList.push(
        navButton({
          to: "#loaded-docs",
          title: "Документи"
        })
      );

    if (isFiles("eApplication"))
      navigationLinksList.push(
        navButton({
          to: "#e-docs",
          title: "еЗаява",
          icon: eDocApplicationIcon
        })
      );

    if (isFiles("eCertificate", true))
      navigationLinksList.push(
        navButton({
          to: "#e-docs",
          title: "єСертифікат",
          icon: eDocIcon
        })
      );

    if (application?.annulmentApplications?.length)
      navigationLinksList.push(
        navButton({
          title: "Анулювання",
          to: "#annulment"
        })
      );

    if (isFiles("eDecline", true))
      navigationLinksList.push(
        navButton({
          to: "#e-docs",
          title: "єВідмова",
          icon: eDocDecline
        })
      );

    if (isFiles("eAnnulmentApplication"))
      navigationLinksList.push(
        navButton({
          title: "еЗаява на ануляцію",
          to: "#e-docs",
          icon: eDocAnnulAltIcon
        })
      );

    if (application?.remarksForUser?.length)
      navigationLinksList.push(
        navButton({
          title: "Зауваження",
          to: "#remarks",
          count: application?.remarksForUser?.length
        })
      );

    return navigationLinksList;
  }, [isFiles, navButton, application?.remarksForUser]);
  const initFields = useMemo(() => {
    if (isEmpty(application?.products)) return [];
    const products = application.products.map((product) => {
      const breadList = speciesList.filter((item) =>
        product.woodSpecieUuids.includes(item.value)
      );
      const breadStirng = breadList.reduce((prevVal, currentVal) => {
        return (prevVal =
          prevVal + (prevVal.length ? ", " : "") + currentVal.label);
      }, "");

      const woodGradeList = sortList.filter((item) =>
        product.woodGradeUuids.includes(item.value)
      );
      const woodGradeString = woodGradeList.reduce((prevVal, currentVal) => {
        return (prevVal =
          prevVal + (prevVal.length ? ", " : "") + currentVal.label);
      }, "");

      const productRow = {
        product: product.productName,
        standard:
          standardList.find(
            (item) => item.value === product.productStandardUuid
          )?.label || "",
        description: product.note || "",
        bread: breadStirng || "",
        sort: woodGradeString || "",
        diameter: product.diameter || "",
        thickness: product.height || "",
        width: product.width || "",
        length: product.length || "",
        count: product.quantity,
        unit: unitList.find(
          (item) => item.value === product.quantityMeasurementUnitUuid
        )?.label,
        price: product.price,
        amount: product.totalPrice,
        uktzed: product.uktzed
      };

      return productRow;
    });
    const { quantity, amount } = calculateTotals(products);

    setProductsTotalQuantity(quantity);
    setProductsTotalAmount(amount);

    return products;
  }, [application]);
  const getEDocs = useMemo(() => {
    const docs = [];
    if (isFiles("eApplication"))
      docs.push({
        docImg: eDocIcon,
        docTitle: "еЗаява",
        onClick: () => onDocumentView(application?.files?.eApplication[0])
      });
    if (isFiles("eCertificate", true))
      docs.push({
        docImg: eDocApplicationIcon,
        docTitle: "єСертифікат",
        onClick: () => onDocumentView(application?.files?.eCertificate[0])
      });
    if (application?.annulmentApplications?.length) {
      application?.annulmentApplications.forEach((item) => {
        if (item.files?.eAnnulmentApplication.length)
          docs.push({
            docImg: eDocAnnulAltIcon,
            docTitle: "еЗаява на ануляцію",
            docAdditionalInfoTitle: "Причина заяви:",
            docAdditionalInfoValue: item.annulmentBasisDescription,
            onClick: () => onDocumentView(item.files?.eAnnulmentApplication[0])
          });
        if (item.files?.eAnnulmentDecline.length)
          docs.push({
            docImg: eDocIcon,
            docTitle: "Відмова на Ануляцію",
            docAdditionalInfoTitle: "Причина відмови:",
            docAdditionalInfoValue: item.declineDescription,
            onClick: () => onDocumentView(item.files?.eAnnulmentDecline[0])
          });
      });
    }
    if (isFiles("eDecline", true))
      docs.push({
        docImg: eDocDecline,
        docTitle: "єВідмова",
        onClick: () => onDocumentView(application?.files?.eDecline[0])
      });
    if (isFiles("eAnnulmentApplication"))
      docs.push({
        docImg: eDocAnnulAltIcon,
        docTitle: "еЗаява на ануляцію",
        onClick: () =>
          onDocumentView(application?.files?.eAnnulmentApplication[0])
      });
    return docs;
  }, [application?.annulmentApplications, application?.files, isFiles]);
  const applicationCertificateTitle = useMemo(() => {
    if (application?.type === "annulment")
      return "Заявка про анулювання сертифікату про походження лісоматеріалів та виготовлених з них пиломатеріалів в електронній формі";
    return "Заявка на отримання сертифікату про походження лісоматеріалів та виготовлених з них пиломатеріалів в електронній формі";
  }, [application, applicationType]);

  useEffect(() => {
    dispatch(features.dataset.actions.fetchStandardListRequest());
    dispatch(features.dataset.actions.fetchSizeListRequest());
    dispatch(features.dataset.actions.fetchSpeciesListRequest());
    dispatch(features.dataset.actions.fetchSortListRequest());
    dispatch(features.dataset.actions.fetchUnitListRequest());
  }, []);
  useEffect(() => {
    if (applicationId) {
      dispatch(
        features.officialApplicationReview.actions.fetchApplicationRequest({
          params: { uuid: applicationId, applicationType },
          onError: (errorsRes) => {
            parseResErrors({ errorsRes });
          }
        })
      );
    }

    dispatch(
      features.modal.actions.hideModal({
        modalType: "PRELOADER"
      })
    );
  }, [applicationId, applicationType, dispatch]);
  useEffect(() => {
    return () => {
      dispatch(features.officialApplicationReview.actions.clearApplication());
    };
  }, []);
  useEffect(() => {
    let buyer;
    let buyerCountry;
    let consignee;
    let consigneeCountry;
    if (application?.invoice) {
      dispatch(
        features.application.actions.fetchSelectedOrganizationsListRequest({
          params: {
            externalId: application.invoice.buyerExternalId
          },
          onSuccess: (response) => {
            buyer = response.name;
            buyerCountry = response.country;
            setInvoiceData((prevState) => ({
              ...prevState,
              buyer,
              buyerCountry
            }));
          }
        })
      );
      dispatch(
        features.application.actions.fetchSelectedOrganizationsListRequest({
          params: {
            externalId: application.invoice.consigneeExternalId
          },
          onSuccess: (response) => {
            consignee = response.name;
            consigneeCountry = response.country;
            setInvoiceData((prevState) => ({
              ...prevState,
              consignee,
              consigneeCountry
            }));
          }
        })
      );
    }
  }, [application, dispatch]);
  useEffect(() => {
    if (application) {
      setIsReadonly(
        application.type !== "certificate" ||
          applicationType === "archived" ||
          applicationType === "annulled" ||
          applicationType === "in-queue"
      );
    }
  }, [application, applicationType]);
  useEffect(() => {
    if (fetchingApplicationCertificateError.unknown !== null) {
      dispatch(
        features.modal.actions.showModal({
          modalType: "GENERATE_E_DOCUMENT_ERROR_MODAL",
          modalProps: {
            percentages: application?.checkedPercent,
            onClose: () => {
              dispatch(
                features.officialApplicationReview.actions.clearApplicationCertificateData()
              );
            },
            error: fetchingApplicationCertificateError.unknown
          }
        })
      );
    }
  }, [
    application?.checkedPercent,
    dispatch,
    fetchingApplicationCertificateError.unknown
  ]);

  return (
    <CertificatesLayout
      navLinks={navigationLinks}
      title={applicationCertificateTitle}
      isOfficial
      navTabsClassName={
        application?.remarksForUser?.length > 0
          ? styles["declined-page-tabs"]
          : null
      }
      subtitle={
        <NavigationHeadSubtitle
          {...application?.applicationHeader}
          isOfficial
        />
      }
    >
      <PreloaderWrapper
        loading={fetchingApplicationLoading || addingRemarkLoading}
        withModal
      >
        {application?.applicant && (
          <Applicant
            name={application.applicant?.organizationName}
            organizationEdrpou={application.applicant?.organizationEdrpou}
            fio={application.applicant?.fullName}
            drfo={application.applicant?.drfo}
            organizationAddress={application.applicant?.organizationAddress}
            isOfficial={true}
          />
        )}
        {application?.type === "annulment" ? (
          <Certificate data={application} id="application-type" />
        ) : (
          <>
            <SectionDelimiter />
            <div id="application-type">
              <Invoice
                buyer={invoiceData.buyer}
                buyerCountry={invoiceData.buyerCountry}
                consignee={invoiceData.consignee}
                consigneeCountry={invoiceData.consigneeCountry}
                docs={[
                  <SectionDoc
                    textItems={[
                      {
                        title: "Номер договору",
                        content: application?.invoice?.contractNumber
                      },
                      {
                        title: "Дата договору",
                        content: formatDate(application?.invoice?.contractDate)
                          .date
                      }
                    ]}
                    textPosition="LEFT"
                    loadedFiles={application?.files?.contract ?? []}
                    remark={application?.remarks?.contract}
                    blockType={"contract"}
                    isOfficialRemarkControl={!isReadonly}
                    onDocumentView={onDocumentView}
                  />,
                  <SectionDoc
                    textItems={[
                      {
                        title: "Номер інвойсу",
                        content: application?.invoice?.invoiceNumber
                      },
                      {
                        title: "Дата інвойсу",
                        content: formatDate(application?.invoice?.invoiceDate)
                          .date
                      }
                    ]}
                    textPosition="LEFT"
                    loadedFiles={application?.files?.invoice ?? []}
                    remark={application?.remarks?.invoice}
                    blockType={"invoice"}
                    isOfficialRemarkControl={!isReadonly}
                    onDocumentView={onDocumentView}
                  />,
                  <SectionDoc
                    textItems={[
                      {
                        title: "Номер специфікації",
                        content: application?.invoice?.specificationNumber
                      },
                      {
                        title: "Дата специфікації",
                        content: formatDate(
                          application?.invoice?.specificationDate
                        ).date
                      }
                    ]}
                    textPosition="LEFT"
                    loadedFiles={application?.files.specification ?? []}
                    remark={application?.remarks?.specification}
                    blockType={"specification"}
                    isOfficialRemarkControl={!isReadonly}
                    onDocumentView={onDocumentView}
                  />
                ]}
              />
            </div>
            <SectionDelimiter />
            <Products
              terms={application?.deliveryConditionName}
              currency={application?.currencyName}
              sizeUnits={application?.geometricDimensionName}
              defaultData={initFields}
              columns={productColumns}
              isOfficial
              count={productsTotalQuantity}
              amount={productsTotalAmount}
              isOfficialEdit={!isReadonly}
              remark={application?.remarks?.products}
              blockType={"products"}
            />
            <SectionDelimiter />
            <div id="basic-issuing">
              {application?.woodCards ? (
                <BasisIssuingCertificateWoodCards
                  documents={application?.basisDocuments}
                  woodCards={application?.woodCards}
                  applicationUuid={application?.uuid}
                  isReadonly={isReadonly}
                  isOfficial
                />
              ) : (
                <BasisIssuingCertificate
                  documents={application?.basisDocuments}
                  applicationUuid={application?.uuid}
                  isReadonly={isReadonly}
                  isOfficial
                />
              )}
            </div>
          </>
        )}
        {application?.trustedDocData && (
          <TrustedDoc
            {...application.trustedDocData}
            isAnnul={application?.type === "annulment"}
            docs={[
              <SectionDoc
                textItems={[
                  {
                    title: "Дата видачі документу",
                    content: formatDate(application.trustedDocData.date).date
                  },
                  {
                    title: "Термін дії документу",
                    content: formatDate(application.trustedDocData.dateEnd).date
                  }
                ]}
                textPosition="LEFT"
                loadedFiles={application.files["trustedDoc"]}
                onDocumentView={(file) => onDocumentView(file)}
              />
            ]}
          />
        )}
        <div id="loaded-docs">
          {isFiles("additional") && application?.type !== "annulment" && (
            <OtherDocs
              docs={[
                <VisualUploadMultipleFiles
                  title={"Документи не завантажено"}
                  accept={{
                    "application/pdf": [".pdf"],
                    "image/jpeg": [".jpg"]
                  }}
                  loadedFiles={application?.files?.additional}
                  onDocumentView={onDocumentView}
                  readonly
                />
              ]}
            />
          )}
        </div>
        {(isFiles("eCertificate", true) || isFiles("eApplication")) && (
          <div id="e-docs">
            <EDocsSection docs={getEDocs} />
          </div>
        )}
        {application?.annulmentApplications?.length > 0 &&
          application?.annulmentApplications.some(
            (el) => el?.officialFullname
          ) && (
            <Section
              title="Анулювання"
              className={styles["annulment-section"]}
              titleClassName={styles["annulment-section__title"]}
              id="annulment"
            >
              {application?.annulmentApplications.map((annulmentItem, i) => (
                <div
                  className={styles["annulment-section__annulment"]}
                  key={`${annulmentItem.annulmentBasisDescription}:${i}`}
                >
                  <SectionText title="Чиновник">
                    {`${annulmentItem.officialFullname}${
                      annulmentItem.officialPosition &&
                      ", " + annulmentItem.officialPosition
                    }${
                      annulmentItem.officialOffice &&
                      ", " + annulmentItem.officialOffice
                    }`}
                  </SectionText>
                  {annulmentItem.annulmentBasisDescription && (
                    <SectionText title="Причина анулювання">
                      {annulmentItem.annulmentBasisDescription}
                    </SectionText>
                  )}
                  {annulmentItem?.files?.annulmentApplicationDocumentsCopy
                    .length > 0 && (
                    <div
                      className={
                        styles["annulment-section__attached-files-container"]
                      }
                    >
                      <VisualUploadMultipleFiles
                        title="Завантажити скан-копію"
                        accept={{
                          "application/pdf": [".pdf"],
                          "image/jpeg": [".jpg"]
                        }}
                        loadedFiles={
                          annulmentItem.files.annulmentApplicationDocumentsCopy
                        }
                        onDocumentView={onDocumentView}
                        readonly={true}
                      />
                    </div>
                  )}
                </div>
              ))}
            </Section>
          )}
        {application?.remarksForUser?.length > 0 && (
          <Section
            title="Зауваження"
            className={styles["concern-section"]}
            titleClassName={styles["concern-section__title"]}
            id="remarks"
          >
            {application?.remarksForUser.map((item, i) => (
              <div className={styles["concern-section__concern"]} key={i}>
                {!(
                  application?.remarksForUser[i - 1]?.blockType ===
                  item.blockType
                ) && <h3>{item.blockType}</h3>}
                <SectionText title="Тип зауваження">
                  {item.errorType}
                </SectionText>
                <SectionText title="Опис зауваження">
                  {item.description}
                </SectionText>
                {item.basisDocumentDetails && (
                  <SectionText title="Документ">
                    {`
                        ${item.basisDocumentDetails.typeName}
                        ${item.basisDocumentDetails.series}
                        ${item.basisDocumentDetails.number}
                        ${formatDate(item.basisDocumentDetails.date).date}
                        ${
                          item.basisDocumentDetails?.chainNumber
                            ? `Номер ланцюга ${item.basisDocumentDetails?.chainNumber?.toString()}`
                            : ""
                        }
                        `}
                  </SectionText>
                )}
              </div>
            ))}
          </Section>
        )}
        {application?.annulmentBasisDescription && (
          <GroundsForCancellation
            text={application?.annulmentBasisDescription}
            loadedFiles={application?.files?.annulmentApplicationDocumentsCopy}
            onDocumentView={onDocumentView}
          />
        )}
        <SectionDelimiter />
        <div className={styles["controls-wrapper"]}>
          {isReadonly && application?.type !== "annulment" ? (
            <CommonButton
              className={styles["create-certificate__create-btn"]}
              label="Назад до кабінету"
              outlined={true}
              onClick={onBack}
            />
          ) : (
            <>
              <CommonButton
                label="Відмовити"
                onClick={() => onDecision(false)}
                outlined
              />
              <CommonButton
                label={
                  application?.type === "annulment"
                    ? "Перевірити та анулювати"
                    : "Затвердити"
                }
                onClick={() => onDecision(true)}
              />
            </>
          )}
        </div>
      </PreloaderWrapper>
    </CertificatesLayout>
  );
};

export default OfficialCertificateReviewPage;
