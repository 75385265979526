import cn from "classnames";
import { useNavigate } from "react-router-dom";

import {
  Cell,
  Header,
  HeaderGroup,
  Row,
  flexRender,
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table";

import { CommonButton } from "gov-ua-ui";

import TreeTableArrowIcon from "assets/images/icons/tree_table_arrow_icon.svg";

import ChildrenTable from "./components/ChildrenTable/ChildrenTable";
import styles from "./tree-table.module.scss";

interface TreeTableInterface {
  defaultData: any;
  columns: Array<any>;
  isOfficial?: boolean;
  isReadonly?: boolean;
}

const TreeTable = ({
  defaultData,
  columns,
  isOfficial,
  isReadonly
}: TreeTableInterface) => {
  const table = useReactTable({
    data: defaultData,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(
      `documents/transition/search?parent=${
        defaultData[defaultData.length - 1].id
      }`
    );
  };

  return (
    <div className={styles["tree-table-container"]}>
      {table.getRowModel().rows.map((row: Row<any>, index: number) => (
        <div
          className={styles["tree-table"]}
          key={row.id}
          style={{
            marginLeft: index >= 1 ? "15px" : "",
            borderLeft:
              index > 0
                ? `1px ${
                    index + 1 < table.getRowModel().rows.length
                      ? "black"
                      : "transparent"
                  } solid`
                : ""
          }}
        >
          <img
            src={TreeTableArrowIcon}
            alt="Arrow down icon"
            className={cn(styles["tree-table__img"], {
              [styles["tree-table__img_none"]]: index === 0
            })}
          />
          <div className={styles["tree-table__wrapper"]}>
            <table
              className={cn(styles["tree-table__table"], {
                [styles["tree-table__table_last-item"]]:
                  index === table.getRowModel().rows.length - 1
              })}
            >
              <thead>
                {table
                  .getHeaderGroups()
                  .map((headerGroup: HeaderGroup<any>) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map(
                        (header: Header<any, unknown>) => (
                          <th key={header.id}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </th>
                        )
                      )}
                    </tr>
                  ))}
              </thead>
              <tbody>
                {defaultData[index].primal && (
                  <tr
                    className={cn(
                      styles["tree-table__label"],
                      styles["tree-table__label_first"]
                    )}
                  >
                    <td>первинний</td>
                  </tr>
                )}
                {index === defaultData.length - 1 &&
                  defaultData[0].chainChecked &&
                  !defaultData[row.id]?.children?.length && (
                    <tr
                      className={cn(
                        styles["tree-table__label"],
                        styles["tree-table__label_last"]
                      )}
                    >
                      <td>ланцюжок закритий</td>
                    </tr>
                  )}

                <tr>
                  {row
                    .getVisibleCells()
                    .map((cell: Cell<any, unknown>, index: number) => {
                      const allRowsCount = table.getRowModel().rows.length;
                      const currentRowIndex = parseInt(row.id) + 1;
                      const canDelete = allRowsCount === currentRowIndex;

                      if (
                        !canDelete &&
                        !isOfficial &&
                        cell.column.id === "delete"
                      )
                        return <td key={cell.id}></td>;
                      return (
                        <td
                          key={cell.id}
                          className={cn({
                            [styles["tree-table__doc-cell"]]:
                              table.options.columns[index].meta?.docCell,
                            [styles["tree-table__button-cell"]]:
                              table.options.columns[index].meta?.buttonCell
                          })}
                        >
                          {table.options.columns[index].meta
                            ?.isOverflowEllipses ? (
                            <div
                              className={
                                styles["tree-table__overflow-ellipses"]
                              }
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </div>
                          ) : (
                            flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )
                          )}
                        </td>
                      );
                    })}
                </tr>
              </tbody>
            </table>
            {defaultData[row.id]?.children?.length > 0 && (
              <>
                <ChildrenTable data={defaultData[row.id]?.children} />
                {index === defaultData.length - 1 &&
                  defaultData[0].chainChecked && (
                    <div className={styles["tree-table__label_children"]}>
                      ланцюжок закритий
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
      ))}
      {!defaultData[0].chainChecked && !isOfficial && !isReadonly && (
        <div
          className={styles["tree-table"]}
          style={{
            marginLeft: "55px",
            borderLeft: "1px transparent solid"
          }}
        >
          <img
            src={TreeTableArrowIcon}
            alt="Arrow down icon"
            className={cn(styles["tree-table__img"])}
          />
          <CommonButton
            outlined={true}
            className={styles["tree-table__btn"]}
            label="Додати перехідний"
            onClick={handleButtonClick}
          />
        </div>
      )}
    </div>
  );
};

export default TreeTable;
