import ApiProvider from "./API";

export const fetchBasisDocs = ({ params, fields }) => {
  return ApiProvider()
    .client.get(`/basis_documents/search`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const deleteBasisDocument = ({ params, fields }) => {
  return ApiProvider()
    .client.delete(
      `/applications/${params.applicationUuid}/basis_documents/${params.documentUuid}`,
      fields
    )
    .then((response) => response)
    .catch((error) => error.response);
};

export const deleteBasisDocumentFile = ({ params }) => {
  return ApiProvider()
    .client.delete(
      `basis_documents/${params.documentUuid}/files/${params.fileUuid}`
    )
    .then((response) => response)
    .catch((error) => error.response);
};

export const deleteAllBasisDocuments = ({ params, fields }) => {
  return ApiProvider()
    .client.delete(
      `/applications/${params.applicationUuid}/basis_documents`,
      fields
    )
    .then((response) => response)
    .catch((error) => error.response);
};

export const createDocument = ({ params, fields }) => {
  return ApiProvider()
    .client.post(`basis_documents`, fields)
    .then((response) => response)
    .catch((error) => error.response);
};

export const removeUnusedZSN = ({ params, fields }) => {
  return ApiProvider()
    .client.delete(`basis_documents`, { data: { document_ids: fields } })
    .then((response) => response)
    .catch((error) => error.response);
};

export const unionDocument = ({ fields }) => {
  return ApiProvider()
    .client.post(`basis_documents/union`, fields)
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchDocument = ({ params, fields }) => {
  return ApiProvider()
    .client.get(`/basis_documents/${params.uuid}`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchDocumentDetailedInfo = ({ params, fields }) => {
  return ApiProvider()
    .client.get(`/basis_documents/${params.uuid}`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchPreviousDocumentsDetailedInfo = ({ params, fields }) => {
  return ApiProvider()
    .client.post("/basis_documents/previous_usage", params)
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchUsedDocumentDetailedInfo = ({ params, fields }) => {
  return ApiProvider()
    .client.get(`/application_basis_documents/${params.uuid}`, fields)
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchUsedWoodCardDocumentDetailedInfo = ({ params, fields }) => {
  return ApiProvider()
    .client.get(
      `/applications/${params.appUuid}/application_wood_card/${params.woodCardUuid}`,
      fields
    )
    .then((response) => response)
    .catch((error) => error.response);
};

export const updateDocument = ({ params, fields }) =>
  ApiProvider()
    .client.put(`basis_documents/${params.basisDocumentUuid}`, fields)
    .then((response) => response)
    .catch((error) => error.response);

export const createReturnWaste = ({ params, fields }) =>
  ApiProvider()
    .client.put(`basis_documents/${params.documentUuid}/waste`, fields)
    .then((response) => response)
    .catch((error) => error.response);
