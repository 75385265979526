import cn from "classnames";
import { createColumnHelper } from "@tanstack/react-table";
import { Alert, CommonButton, SimpleTooltip } from "gov-ua-ui";
import { useCallback, useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { getDocTypeAlias } from "constant";
import features from "features";
import { formatDate } from "helpers";
import { IRootState } from "reducer";
import { IApplicationState } from "scenes/subject/applications/ApplicationPage/ducks";
import { IBasisDoc } from "scenes/subject/applications/ApplicationPage/interfaces";

import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";
import Section from "components/Section/Section";
import BasicDocs from "components/certificateComponents/viewCertificateComponents/BasicDocs/BasicDocs";
import OfficialControls from "components/certificateComponents/viewCertificateComponents/OfficialControls/OfficialControls";

import DeleteIcon from "assets/images/icons/delete.svg";
import EyeIcon from "assets/images/icons/eye.svg";
import DownloadIcon from "assets/images/icons/download-white.svg";

import styles from "./basis-issuing-certificate.module.scss";

interface ITable extends IBasisDoc {
  amount: number;
  docs: string;
  delete?: string;
  remark?: string;
  view?: string;
}

interface BasisIssuingCertificateInterface {
  applicationUuid?: string;
  documents?: Array<IBasisDoc>;
  isOfficial?: boolean;
  isReadonly?: boolean;
}

const BasisIssuingCertificate = ({
  applicationUuid,
  documents,
  isOfficial,
  isReadonly
}: BasisIssuingCertificateInterface): JSX.Element => {
  const navigate = useNavigate();
  const [isPdDetailsModalOpen, setIsPdDetailsModalOpen] =
    useState<boolean>(false);
  const [popStateHandler, setPopStateHandler] = useState(null);

  const { applicationId } = useParams();
  const dispatch = useDispatch();
  const {
    application,
    fetchingApplicationLoading,
    downloadBasicsExcelLoading
  } = useSelector<IRootState, IApplicationState>((state) => state.application);

  const handleButtonClick = () => {
    navigate("documents/primary/search");
  };

  const onModalClose = () => {
    setIsPdDetailsModalOpen(false);
    window.removeEventListener("popstate", popStateHandler);
    window.history.back();
  };

  const onViewClick = useCallback(
    (info) => {
      const docUuid = info.row.original.uuid;
      const docId = info.row.original.externalId;
      const isPrimal = info.row.original.primal;
      const docType = getDocTypeAlias(
        info.row.original.supportDocumentTypeName
      );

      dispatch(
        features.modal.actions.showModal({
          modalType: "BASIS_DOC_VIEW",
          modalProps: {
            docType,
            docUuid,
            docId,
            isPrimal,
            onModalClose
          }
        })
      );
      setIsPdDetailsModalOpen(true);
    },
    [dispatch]
  );

  useEffect(() => {
    const handlePopState = () => {
      if (isPdDetailsModalOpen) {
        dispatch(features.modal.actions.hideModal());
        setIsPdDetailsModalOpen(false);
        window.history.replaceState(null, null, window.location.href);
      }
    };

    if (isPdDetailsModalOpen) {
      window.history.pushState(null, null, window.location.href);
      window.addEventListener("popstate", handlePopState);
      setPopStateHandler(() => handlePopState);
    } else {
      window.removeEventListener("popstate", handlePopState);
    }

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isPdDetailsModalOpen, dispatch, setIsPdDetailsModalOpen]);

  const basicDocsColumnsHelper = createColumnHelper<ITable>();

  const basicDocsColumns = [
    basicDocsColumnsHelper.accessor("supportDocumentTypeName", {
      cell: (info: any) => info.getValue(),
      header: () => "Документ"
    }),
    basicDocsColumnsHelper.accessor("series", {
      cell: (info: any) => info.getValue(),
      header: () => "Серія"
    }),
    basicDocsColumnsHelper.accessor("number", {
      cell: (info: any) => info.getValue(),
      header: () => "Номер"
    }),
    basicDocsColumnsHelper.accessor("date", {
      cell: (info: any) => formatDate(info.getValue()).date,
      header: () => "Дата"
    }),
    basicDocsColumnsHelper.accessor("sourceOrganizationName", {
      cell: (info: any) => info.getValue(),
      header: () => "Лісокористувач/Продавець",
      meta: {
        isOverflowEllipses: true
      }
    }),
    basicDocsColumnsHelper.accessor("recipientOrganizationName", {
      cell: (info: any) => info.getValue(),
      header: () => "Контрагент",
      meta: {
        isOverflowEllipses: true
      }
    }),
    basicDocsColumnsHelper.accessor("takenQuantity", {
      cell: (info: any) => info.getValue(),
      header: () => "Кількість"
    }),
    ...(!isReadonly
      ? [
          basicDocsColumnsHelper.accessor(isOfficial ? "remark" : "delete", {
            cell: (info: any) =>
              isOfficial ? (
                <>
                  <OfficialControls
                    remark={info.getValue()}
                    blockType={"application_basis_document"}
                    isSmall
                  />
                </>
              ) : (
                <CommonButton
                  image={DeleteIcon}
                  round={true}
                  outlined={true}
                  className={styles["delete-icon-button"]}
                  onClick={() =>
                    onDeleteClick({
                      applicationUuid,
                      documentUuid: info.row.original.uuid
                    })
                  }
                />
              ),
            header: () => "",
            meta: {
              buttonCell: true
            }
          })
        ]
      : []),
    basicDocsColumnsHelper.accessor("view", {
      cell: (info: any) => (
        <CommonButton
          image={EyeIcon}
          round={true}
          outlined={true}
          onClick={() => onViewClick(info)}
        />
      ),
      header: () => "",
      meta: {
        buttonCell: true
      }
    })
  ];

  const onDeleteClick = useCallback(
    ({ applicationUuid, documentUuid }) => {
      dispatch(
        features.modal.actions.showModal({
          modalType: "CONFIRM_ACTION",
          modalProps: {
            notificationText:
              "Ви впевнені, що хочете видалити документ підстави?",
            acceptLabel: "Так, я впевнений",
            onAccept: () => {
              dispatch(
                features.modal.actions.showModal({
                  modalType: "PRELOADER",
                  modalProps: {
                    title: "Опрацювання запиту",
                    loading: true
                  }
                })
              );
              dispatch(
                features.application.actions.deleteBasisDocumentRequest({
                  params: { applicationUuid, documentUuid },
                  onSuccess: () => {
                    dispatch(features.modal.actions.hideModal());
                    dispatch(
                      features.application.actions.fetchApplicationRequest({
                        params: { uuid: applicationUuid }
                      })
                    );
                  },
                  onError: () => {
                    dispatch(features.modal.actions.hideModal());
                  }
                })
              );
            }
          }
        })
      );
    },
    [dispatch]
  );

  const isBasisDocuments = useMemo(() => {
    if (documents?.length) {
      return documents.filter((item) => item.primal).length;
    }

    return false;
  }, [documents]);

  const isAddPrimalActive = useMemo(() => {
    let canAddMore = true;
    if (isOfficial || isReadonly) return false;
    documents?.forEach((document) => {
      if (document.primal) {
        if (document.chainChecked === false) {
          canAddMore = false;
        }
      }
    });

    return canAddMore;
  }, [documents, isOfficial, isReadonly]);

  const handleDownloadExcelClick = () => {
    dispatch(
      features.application.actions.downloadBasicDocumentsExcelRequest({
        params: {
          uuid: applicationId
        },
        onSuccess: (data) => (window.location.href = data?.url)
      })
    );
  };

  return (
    <PreloaderWrapper
      loading={fetchingApplicationLoading || downloadBasicsExcelLoading}
    >
      <Section
        title={"Підстава видачі сертифіката"}
        color="WHITE"
        className={styles["certificate-issuing"]}
      >
        {isBasisDocuments ? (
          <BasicDocs
            defaultData={documents}
            columns={basicDocsColumns}
            isOfficial={isOfficial}
            isReadonly={isReadonly}
          />
        ) : (
          <Alert
            className={styles["certificate-issuing__reminder"]}
            type="warning"
            withIcon
          >
            {application?.products.length > 0
              ? "Спочатку додайте первинний документ підставу (за яким була реалізована лісопродукція лісокористувачем-продавцем на внутрішньому ринку, або підприємством - відправником за МД на зовнішньому ринку), а потім - перехідні документи підстави."
              : "Перед додаванням документа підстави потрібно заповнити дані про продукцію."}
          </Alert>
        )}
        <div
          className={cn(styles["buttons-wrapper"], {
            [styles["buttons-wrapper__without-add-primal"]]: !isAddPrimalActive
          })}
        >
          {isAddPrimalActive && (
            <CommonButton
              outlined={true}
              className={styles["certificate-issuing__btn"]}
              label="Додати первинний документ"
              onClick={handleButtonClick}
              disabled={application?.products.length > 0 ? false : true}
            />
          )}
          {isBasisDocuments && (
            <>
              <div
                data-tooltip-id="subject-tooltip"
                data-tooltip-content={
                  "Завантажити звіт у форматі Excel з інформацією про використану в заявці продукцію за всіма первинними документами"
                }
                className={styles["download-report-button-container"]}
              >
                <CommonButton
                  label="Завантажити звіт"
                  image={DownloadIcon}
                  onClick={handleDownloadExcelClick}
                />
              </div>
              <SimpleTooltip
                id="subject-tooltip"
                float
                place="right"
                className={styles["tooltip-hint"]}
                noArrow
                style={{
                  transform: "translateY(-120px)"
                }}
              />
            </>
          )}
        </div>
      </Section>
    </PreloaderWrapper>
  );
};

export default BasisIssuingCertificate;
