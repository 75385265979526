import { createSlice } from "@reduxjs/toolkit";

export interface IStatistics {
  annulmentDeclinesCount: number;
  inQueueByOfficialInterregionalOffice: number;
  inProcessByOfficialInterregionalOffice: number;
  processedDuringMonthCount: number;
  allInYourArea: number;
  annulledForSignCount: number;
  archivedCount: number;
  inProcessCount: number;
  officialsAtWork: number;
  processedDuringWeekCount: number;
  processedTodayCount: number;
}

export interface IDashboardState {
  fetchingStatisticsLoading: boolean;
  statistics: IStatistics;
  responseError: any;
  processingApplicationLoading: boolean;
}

const initialState: IDashboardState = {
  fetchingStatisticsLoading: true,
  statistics: null,
  responseError: null,
  processingApplicationLoading: null
};

export const mainSlice = createSlice({
  name: "officialDashboard",
  initialState,
  reducers: {
    fetchStatisticsRequest: (state) => {
      state.fetchingStatisticsLoading = true;
    },
    fetchStatisticsSuccess: (state, action) => {
      state.fetchingStatisticsLoading = false;
      state.statistics = action.payload;
    },
    fetchStatisticsError: (state, action) => {
      state.fetchingStatisticsLoading = false;
      state.responseError = action.payload;
    },

    startProcessingApplicationRequest: (state) => {
      state.processingApplicationLoading = true;
    },
    startProcessingApplicationSuccess: (state) => {
      state.processingApplicationLoading = false;
    },
    startProcessingApplicationError: (state) => {
      state.processingApplicationLoading = false;
    },

    clearStatistics: (state) => {
      state.statistics = initialState.statistics;
      state.responseError = initialState.responseError;
    }
  }
});

export const { actions, reducer } = mainSlice;
