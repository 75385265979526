import cn from "classnames";
import { BlackEyeIcon, CommonButton, TooltipWidget } from "gov-ua-ui";

import styles from "./document-view.module.scss";

interface DocumentViewInterface {
  docImg: string;
  className?: string;
  onDocumentView?: () => void;
  isEOD?: boolean;
}

const DocumentView = ({
  docImg,
  className,
  onDocumentView,
  isEOD
}: DocumentViewInterface) => {
  return (
    <div className={cn(styles["document-view__image-container"], className)}>
      {isEOD && (
        <TooltipWidget label="Дані по документу завантажено з системи ЕОД" />
      )}
      <img
        src={docImg}
        alt="Document preview"
        className={styles["document-view__image"]}
      />
      {onDocumentView && (
        <div className={styles["document-view__image-buttons-container"]}>
          <CommonButton
            round
            outlined
            image={<BlackEyeIcon />}
            onClick={onDocumentView}
          />
        </div>
      )}
    </div>
  );
};

export default DocumentView;
