import { SwitchButton } from "gov-ua-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate, useSearchParams } from "react-router-dom";

import { getDocTypeAlias } from "constant";
import features from "features";
import {
  formatDate,
  formatNumber,
  getChainNumber,
  parseFilesErrors
} from "helpers";
import { getSearchTitle } from "helpers/bread-crumbs";
import { IRootState } from "reducer";
import { IApplicationState } from "scenes/subject/applications/ApplicationPage/ducks";
import { IProductUsageState } from "scenes/subject/documents/ProductUsage/ducks";
import { BASIS_DOC_TYPE } from "scenes/subject/documents/interfaces/interfaces";

import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import DocumentForUse from "components/certificateComponents/viewCertificateComponents/DocumentForUse/DocumentForUse";
import ApplicationLayout from "components/layouts/ApplicationLayout/ApplicationLayout";
import FormControls from "components/railDocsComponents/FormControls/FormControls";
import Summary from "components/railDocsComponents/Summary/Summary";
import ZSNDocView from "components/railDocsComponents/ZSNDocView/ZSNDocView";
import ZSN from "./zsn/zsn";

import DodPDFIcon from "assets/images/icons/doc_preview.svg";
import DocIcon from "assets/images/icons/e_doc.svg";

import styles from "./specify-current-zn-usage-layout.module.scss";

interface SpecifyCurrentUsageLayoutInterface {
  data?: any;
  applicationId?: string;
  docId?: string;
  chain?: string;
}

const SpecifyCurrentUsageLayout = ({
  data,
  applicationId,
  chain
}: SpecifyCurrentUsageLayoutInterface) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const parentId = parseInt(searchParams.get("parent"));
  const [isEmptyFiles, setIsEmptyFiles] = useState(null);
  const [isEmptyChildFiles, setIsEmptyChildFiles] = useState([]);

  const [submitCount, setSubmitCount] = useState(0);
  const [isGrouped, setIsGrouped] = useState<boolean>(false);

  const { application } = useSelector<IRootState, IApplicationState>(
    (state) => state.application
  );
  const { isJoiningDocumentsLoaded, joiningDocumentLoading } = useSelector<
    IRootState,
    IProductUsageState
  >((state) => state.productUsage);

  useEffect(
    () => () => dispatch(features.productUsage.actions.clearDocumentData()),
    []
  );

  const backPath = useMemo(() => {
    const path = parentId
      ? `transition/search?parent=${parentId}`
      : "primary/search";
    return `/personal/applications/${applicationId}/documents/${path}`;
  }, [applicationId, parentId]);

  const handleRadioButtonClick = () => {
    setIsGrouped((prevState) => !prevState);
  };

  const onBack = useCallback(() => navigate(backPath), [backPath, navigate]);

  const searchTitle = useMemo(() => {
    return getSearchTitle(!parentId, application?.useWoodCard);
  }, [application?.useWoodCard, parentId]);

  const breadcrumbs = useMemo(
    () => [
      {
        text: "Заявка на видачу сертифіката",
        to: `/personal/applications/${applicationId}`
      },
      {
        text: searchTitle,
        to: backPath
      },
      {
        text: "Зазначання обсягів",
        to: "/"
      }
    ],
    [applicationId, backPath, searchTitle]
  );
  const [totals, setTotals] = useState([]);

  const updateTotals = useCallback(
    (val) => {
      setTotals((totals) => {
        let exist = false;
        const updatedTotals = totals.map((total) => {
          if (total.index === val.index) {
            exist = true;
            const newTotal = {
              ...total,
              ...val,
              original: data?.children[val.index]?.quantityOrigin,
              available: data?.children[val.index]?.quantityAvailable
            };
            return newTotal;
          } else {
            return total;
          }
        });

        if (!exist) {
          const newTotal = {
            ...val,
            original: data?.children[val.index]?.quantityOrigin,
            available: data?.children[val.index]?.quantityAvailable
          };
          updatedTotals.push(newTotal);
        }
        return updatedTotals;
      });
    },
    [data?.children]
  );

  const generateCalculatedTotalsElements = useMemo(() => {
    let original = 0;
    let available = 0;
    let amount = 0;
    let amountPDV = 0;
    let decommissioned = 0;

    totals.forEach((total) => {
      original = original + total.original;
      available = available + total.available;
      amount = amount + total.amount;
      amountPDV = amountPDV + total.amountPDV;
      decommissioned = decommissioned + total.decommissioned;
    });

    return [
      {
        title: "Оригінал",
        value: formatNumber(original, 5)
      },
      {
        title: "Доступно",
        value: formatNumber(available, 5)
      },
      {
        title: "Списано",
        value: formatNumber(decommissioned, 5)
      },
      {
        title: "Сума",
        value: formatNumber(amount, 2, true)
      },
      {
        title: "Сума з ПДВ",
        value: formatNumber(amountPDV, 2, true)
      }
    ];
  }, [totals]);

  const handleSubmit = useCallback(() => {
    const isEmptyChildFiles = data?.children.find(
      (item) => item.files.length === 0
    );
    if (
      !(data.files.length > 0) ||
      (!data.byUnion && isEmptyChildFiles) ||
      data.files.find(
        (file) => file.progress !== 100 && file.progress !== undefined
      )
    ) {
      toastr.error("Помилка", "Завантажте всі файли");
      return;
    }

    if (!totals.find((total) => total.decommissioned > 0)) {
      toastr.error("Помилка", "Виберіть продукцію");
      return;
    }

    const unitList = [
      ...new Set(
        totals
          .filter((item) => item.decommissioned > 0)
          .map((item) => item.measurementUnitName)
      )
    ];
    if (unitList.length === 1) {
      const measurementUnitName = unitList[0];

      const parentDoc = application.basisDocuments.find((item) =>
        parentId > 0 &&
        item.chainNumber === getChainNumber(application?.basisDocuments)
          ? parseInt(item.id) === parentId
          : false
      );
      const parentMeasurementUnitName = parentDoc?.measurementUnitName;
      const parentDecommisioned = parentDoc?.takenQuantity;

      if (
        parentMeasurementUnitName &&
        measurementUnitName === parentMeasurementUnitName
      ) {
        const decommissioned = generateCalculatedTotalsElements.find(
          (item) => item.title === "Списано"
        ).value;

        if (parentDecommisioned < decommissioned && !parentDoc.wasteUsed) {
          toastr.error(
            "Помилка",
            "Кількість використаної продукції повинна дорівнювати, або бути меншою ніж у попередньому документі, при однакових одиницях виміру"
          );
          return;
        }
      }
    }

    dispatch(
      features.modal.actions.showModal({
        modalType: "CONFIRM_ACTION",
        modalProps: {
          notificationText:
            "Ви впевнені що ви ввели правильні обсяги використання? Підтверджуючи дію ви резервуєте обсяги даного ДП для вашої заявки.",
          acceptLabel: "Все вірно, продовжити",
          onAccept: () => {
            dispatch(features.productUsage.actions.joinDocumentReset());
            setSubmitCount(submitCount + 1);
          }
        }
      })
    );
  }, [data, dispatch, submitCount, totals]);

  const handleLoadFiles =
    (docType, uuid?) => (acceptedFiles, rejectedFiles, replaceFile) => {
      if (acceptedFiles)
        acceptedFiles.forEach((file) => {
          dispatch(
            features.productUsage.actions.uploadDocFileRequest({
              params: {
                basisDocumentUuid: uuid ?? data.uuid,
                replaceUuid: replaceFile?.uuid
              },
              fields: {
                fileType: docType,
                file: Object.assign(file, {
                  alias: docType
                })
              }
            })
          );
        });

      parseFilesErrors(rejectedFiles);
    };

  const onDeleteFile = async (file, uuid?) => {
    await new Promise((resolve) => {
      dispatch(
        features.productUsage.actions.deleteDocFileRequest({
          params: {
            documentUuid: uuid ?? data.uuid,
            fileUuid: file.uuid
          },
          type: file.type,
          name: file.name,
          onSuccess: () => resolve(() => true),
          onError: () => resolve(() => true)
        })
      );

      if (file.cancel) {
        file.cancel();
      }
    });
  };

  useEffect(() => {
    if (isJoiningDocumentsLoaded.length && !joiningDocumentLoading) {
      let isSuccess = false;
      isJoiningDocumentsLoaded.forEach((item) =>
        item.loaded === false ? (isSuccess = false) : (isSuccess = true)
      );
      if (isSuccess) navigate(`/personal/applications/${applicationId}`);
      else {
        dispatch(
          features.application.actions.deleteBasisDocumentRequest({
            params: { applicationUuid: applicationId, documentUuid: data.uuid },
            onSuccess: () => {}
          })
        );
      }
    }
  }, [
    applicationId,
    data?.uuid,
    dispatch,
    isJoiningDocumentsLoaded,
    joiningDocumentLoading,
    navigate
  ]);

  useEffect(() => {
    if (data && isEmptyFiles === null) {
      setIsEmptyFiles(!(data.files.length > 0));
      if (!data.byUnion) {
        data.children.forEach((item, index) => {
          if (!(item.files.length > 0))
            setIsEmptyChildFiles((prev) => [...prev, index]);
        });
      }
    }
  }, [data, isEmptyFiles]);

  return (
    <ApplicationLayout className={styles["specify-usage"]}>
      <Breadcrumbs elements={breadcrumbs} />
      <h1 className={styles["specify-usage__title"]}>
        Вказати поточне використання
      </h1>
      {/* TODO delete */}
      {/* {data && (
        <TransitionDocSearchResult
          loadedFiles={data?.files}
          allowUpload={isEmptyFiles}
          image={DocIcon}
          onDeleteFile={onDeleteFile}
          onLoadFiles={handleLoadFiles(BASIS_DOC_TYPE.ZN_SCANCOPY)}
          type={data.typeName}
          serial={data.series}
          number={data.number}
          date={formatDate(data.date).date}
          sellerTitle={"returnDocTitle()"}
          sellerText={data.sourceOrganizationName}
          sellerOrganizationEdrpou={data.sourceOrganizationEdrpou}
          buyerTitle="Контрагент \\ Покупець"
          buyerText={data.recipientOrganizationName}
          buyerOrganizationEdrpou={data.recipientOrganizationEdrpou}
          originalCountTitle="Кількість оригінал"
          originalCountText={data.quantityOrigin}
          availableCountTitle="Кількість доступно"
          availableCountText={data.quantityAvailable}
          className={styles["specify-usage__doc"]}
          zsnList={data.children.map((item, index) => (
            <ZSNDocView
              key={index}
              image={DodPDFIcon}
              type={item.typeName}
              wagon={item.freightCarNumber}
              serial={item.series}
              number={item.number}
              date={formatDate(item.date).date}
              allowUpload={isEmptyChildFiles.includes(index) && !data.byUnion}
              isEOD={data.byUnion}
              files={item.files}
              onDeleteFile={(file) => onDeleteFile(file, item.uuid)}
              onLoadFiles={handleLoadFiles(
                BASIS_DOC_TYPE.ZSN_SCANCOPY,
                item.uuid
              )}
              selectable
              customFields={[
                {
                  title: "Кількість оригінал",
                  text: item.quantityOrigin
                },
                {
                  title: "Кількість доступно",
                  text: item.quantityAvailable
                }
              ]}
            />
          ))}
        />
      )} */}
      {data && (
        <DocumentForUse
          className={styles["specify-usage__doc"]}
          loadedFiles={data.files}
          additionalDocuments={data.additionalDocuments}
          docImg={DocIcon}
          type={getDocTypeAlias(data.typeName)}
          typeName={data.typeName}
          series={data.series}
          number={data.number}
          date={formatDate(data.date).date}
          seller={{
            sellerName: data.sourceOrganizationName,
            sellerExternalId: data.sourceOrganizationExternalId,
            sellerEdrpou: data.sourceOrganizationEdrpou
          }}
          shopper={{
            shopperName: data.recipientOrganizationName,
            shopperExternalId: data.recipientOrganizationExternalId,
            shopperEdrpou: data.recipientOrganizationEdrpou
          }}
          isPrimal={!parentId}
          allowUpload={isEmptyFiles}
          onLoadFiles={handleLoadFiles(
            data.supportDocumentTypeAlias === "md"
              ? "customs_declaration_scan_copy"
              : data.supportDocumentTypeAlias + "_scan_copy"
          )}
          onDeleteFile={onDeleteFile}
          documentId={data.externalId}
          uuid={data.uuid}
          initialCount={data.quantityOrigin}
          availableCount={data.quantityAvailable}
          wasteQuantity={data.wasteQuantity}
          wasteQuantityAvailable={data.wasteQuantityAvailable}
          wasteQuantityPercent={data.wasteQuantityPercent}
          zsnComponentsList={data.children.map((item, index) => (
            <ZSNDocView
              key={index}
              image={DodPDFIcon}
              type={item.typeName}
              wagon={item.freightCarNumber}
              serial={item.series}
              number={item.number}
              date={formatDate(item.date).date}
              allowUpload={isEmptyChildFiles.includes(index) && !data.byUnion}
              isEOD={data.byUnion}
              files={item.files}
              onDeleteFile={(file) => onDeleteFile(file, item.uuid)}
              onLoadFiles={handleLoadFiles(
                BASIS_DOC_TYPE.ZSN_SCANCOPY,
                item.uuid
              )}
              selectable
              customFields={[
                {
                  title: "Кількість оригінал",
                  text: item.quantityOrigin
                },
                {
                  title: "Кількість доступно",
                  text: item.quantityAvailable
                }
              ]}
            />
          ))}
          isHiddenControls
        />
      )}
      <div className={styles["specify-usage__radio-button-wrapper"]}>
        <SwitchButton active={isGrouped} onChange={handleRadioButtonClick} />
        <p>Згрупувати</p>
      </div>
      {data?.children.map((item, index) => (
        <ZSN
          key={index}
          index={index}
          data={item}
          submitCount={submitCount}
          parentId={parseInt(searchParams.get("parent"))}
          chain={chain}
          applicationId={applicationId}
          onUpdateTotals={updateTotals}
          isGrouped={isGrouped}
          // byUnion={data.byUnion}
        />
      ))}
      <Summary
        title="Всього по ЗН"
        elements={generateCalculatedTotalsElements}
      />

      <FormControls onDecline={onBack} onAccept={handleSubmit} />
    </ApplicationLayout>
  );
};

export default SpecifyCurrentUsageLayout;
