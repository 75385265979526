import { createColumnHelper } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";

import { docTypes } from "constant";
import { applicationStatuses } from "dataset/applicationStatuses";
import features from "features";
import { formatDate } from "helpers";
import { getStartIndexOfRowByPage } from "helpers/table-helpers";
import { isEmpty } from "lodash";
import { IRootState } from "reducer";
import { IAnnulledApplicationsState } from "scenes/subject/applications/AnnulledApplicationsPage/ducks";

import BlankPage from "components/BlankPage/BlankPage";
import Preloader from "components/Preloader/Preloader";
import EDocButton from "components/buttons/EDocButton/EDocButton";
import PersonalLayout from "components/layouts/PersonalCabinetLayout/PersonalLayout";
import CommonTable from "components/tables/CommonTable/CommonTable";
import { CommonButton, Pagination, Status } from "gov-ua-ui";

import CopyIcon from "assets/images/icons/copy.svg";
import EyeIcon from "assets/images/icons/eye.svg";

import styles from "./annulled-applications-page.module.scss";

const TABLE_ROWS_COUNT = 5;

interface TableData {
  num: number;
  uuid: string;
  certificateId: string;
  orderedAt: string;
  certificateCreatedAt: string;
  buyer: string;
  consignee: string;
  invoiceNumber: number;
  invoiceAt: string;
  annulationStatus: string | "canceled" | "annul";
  eAnnulmentApplication: string;
  eApplication: string;
  view: string;
  copy: string;
}

const AnnulledPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { fetchingApplicationsLoading, applications } = useSelector<
    IRootState,
    IAnnulledApplicationsState
  >((state) => state.annulledApplications);

  useEffect(() => {
    dispatch(features.annulledApplications.actions.fetchAnnulledRequest());

    return () => {
      dispatch(features.annulledApplications.actions.clearAnnulled());
    };
  }, []);

  const applicationsOptions = useMemo(() => {
    if (isEmpty(applications?.data)) return [];

    const startIndexOfRow = getStartIndexOfRowByPage({
      currentPage: applications?.meta?.currentPage,
      itemsPerPage: applications?.meta?.itemsPerPage
    });

    return applications.data.map((item, i) => ({
      num: startIndexOfRow + i,
      uuid: item.uuid,
      certificateId: item.certificateId,
      orderedAt: `${formatDate(item.orderedAt).date}\n${
        formatDate(item.orderedAt).time
      }`,
      certificateCreatedAt: `${formatDate(item.certificateCreatedAt).date}\n${
        formatDate(item.certificateCreatedAt).time
      }`,
      buyer: item.buyer,
      consignee: item.consignee,
      invoiceNumber: item.invoiceNumber,
      invoiceAt: item.invoiceAt,
      annulationStatus: item.annulationStatus,
      eAnnulmentApplication: {
        title: "еЗаява на ануляцію",
        ...item.eAnnulmentApplication,
        date: formatDate(item?.eAnnulmentApplication?.createdAt).date
      },
      eApplication: {
        title: "еЗаява",
        ...item.eApplication,
        date: formatDate(item.eApplication?.createdAt).date
      },
      view: "Переглянути",
      copy: "Копіювити"
    }));
  }, [applications]);

  const columnHelper = createColumnHelper<TableData>();

  const handleRowClick = useCallback((uuid) => {
    navigate(`/personal/applications/${uuid}`);
  }, []);

  const onCopyClick = useCallback(
    (e, uuid) => {
      e.stopPropagation();

      dispatch(
        features.modal.actions.showModal({
          modalType: "CONFIRM_ACTION",
          modalProps: {
            notificationText:
              'Скопіювати заявку? Зверніть увагу, що документи з розділу заявки "Підстава видачі сертифіката" не копіюються',
            onAccept: () => {
              dispatch(
                features.modal.actions.showModal({
                  modalType: "PRELOADER",
                  modalProps: {
                    title: "Опрацювання запиту",
                    loading: true
                  }
                })
              );
              dispatch(
                features.draftApplications.actions.copyDraftRequest({
                  params: { uuid },
                  onSuccess: () => {
                    dispatch(features.modal.actions.hideModal());
                    toastr.success(
                      "Успіх",
                      "Заявка скопійована і доступна для роботи у розділі “Чернетки”"
                    );
                    dispatch(
                      features.draftApplications.actions.fetchDraftsRequest({
                        fields: {
                          page: 1,
                          limit: TABLE_ROWS_COUNT
                        }
                      })
                    );
                  },
                  onError: () => {
                    dispatch(features.modal.actions.hideModal());
                    toastr.error(
                      "Помилка",
                      "Щось пішло не так. Заявку не вдалось скопіювати"
                    );
                  }
                })
              );
            }
          }
        })
      );
    },
    [TABLE_ROWS_COUNT]
  );

  const columns = [
    columnHelper.accessor("num", {
      cell: (info: any) => (
        <p className={styles["drafts-page__table-number"]}>{info.getValue()}</p>
      ),
      header: () => "№"
    }),
    columnHelper.accessor("certificateId", {
      cell: (info) => info.getValue(),
      header: () => "Номер сертифіката"
    }),
    columnHelper.accessor("orderedAt", {
      cell: (info: any) => info.getValue(),
      header: () => "Дата видачі"
    }),
    columnHelper.accessor("certificateCreatedAt", {
      cell: (info: any) => info.getValue(),
      header: () => "Дата створення заяви"
    }),
    columnHelper.accessor("buyer", {
      cell: (info: any) => info.getValue(),
      header: () => "Покупець"
    }),
    columnHelper.accessor("consignee", {
      cell: (info: any) => info.getValue(),
      header: () => "Вантажоодержувач"
    }),
    columnHelper.accessor("invoiceNumber", {
      cell: (info: any) => info.getValue(),
      header: () => "Номер інвойсу"
    }),
    columnHelper.accessor("invoiceAt", {
      cell: (info: any) => {
        const formattedDate = formatDate(info.getValue());
        return (
          <>
            {formattedDate.date}
            <br />
            {formattedDate.time}
          </>
        );
      },
      header: () => "Дата інвойсу"
    }),
    columnHelper.accessor("annulationStatus", {
      cell: (info: any) => {
        return <Status {...applicationStatuses[info.getValue()]} />;
      },
      header: () => "Ануляція/ Відмова"
    }),
    columnHelper.accessor("eAnnulmentApplication", {
      cell: (info: any) => {
        const eAnnulmentApplication = info.getValue();

        return (
          <EDocButton
            title={eAnnulmentApplication.title}
            contentTitle={eAnnulmentApplication.type}
            contentSubtitle={eAnnulmentApplication.serialNumber}
            contentText={eAnnulmentApplication.date}
            type={docTypes.ANNUL_ALT}
            onClick={(e) => onDocumentView(e, eAnnulmentApplication)}
          />
        );
      },
      header: () => "еЗаява на ануляцію"
    }),
    columnHelper.accessor("eApplication", {
      cell: (info: any) => {
        const eApplication = info.getValue();

        return (
          <EDocButton
            title={eApplication.title}
            contentTitle={eApplication.type}
            contentSubtitle={eApplication.serialNumber}
            contentText={eApplication.date}
            type={docTypes.APPLICATION}
            onClick={(e) => onDocumentView(e, eApplication)}
          />
        );
      },
      header: () => "еЗаява"
    }),
    columnHelper.accessor("view", {
      cell: (info) => (
        <div className={styles["personal-cabinet-page__table-align"]}>
          <CommonButton
            image={EyeIcon}
            round={true}
            outlined={true}
            className={styles["personal-cabinet-page__icon-button"]}
            onClick={() => handleRowClick(info.row.original.uuid)}
          />
        </div>
      ),
      header: () => "Переглянути"
    }),
    columnHelper.accessor("copy", {
      cell: (info: any) => (
        <div className={styles["personal-cabinet-page__table-align"]}>
          <CommonButton
            round={true}
            outlined={true}
            image={CopyIcon}
            className={styles["personal-cabinet-page__icon-button"]}
            onClick={(e) => onCopyClick(e, info.row.original.uuid)}
            disabled={info.row.original.completionPercentage === 0}
          />
        </div>
      ),
      header: () => "Копіювити"
    })
  ];

  const onPageClick = useCallback((event) => {
    const newPage = event.selected + 1;

    dispatch(
      features.annulledApplications.actions.fetchAnnulledRequest({
        fields: { page: newPage }
      })
    );
  }, []);

  const onDocumentView = useCallback(
    (e, file: { name: string; filePath: any; uuid: string }) => {
      e.stopPropagation();

      window.open(file.filePath, "_blank");

      // dispatch(
      //   features.modal.actions.showModal({
      //     modalType: getDocumentViewType(file.name),
      //     modalProps: {
      //       url: file.filePath,
      //       uuid: file.uuid,
      //       withDownload: true
      //     }
      //   })
      // );
    },
    [dispatch]
  );

  return (
    <PersonalLayout>
      <div className={styles["personal-cabinet-page"]}>
        <Status
          title="
          Анульовано
          "
          color="#ffffff"
          background="#464849"
          className={styles["personal-cabinet-page__status"]}
        />

        {fetchingApplicationsLoading ? (
          <Preloader />
        ) : (
          <>
            {!isEmpty(applications?.data) ? (
              <>
                <CommonTable
                  columns={columns}
                  defaultData={applicationsOptions}
                  tableType="ROW-GAP"
                  onRowClick={handleRowClick}
                />
                <Pagination
                  forcePage={applications.meta.currentPage - 1}
                  onPageChange={onPageClick}
                  pageRangeDisplayed={applications.meta.itemsPerPage}
                  pageCount={applications.meta.totalPages}
                  renderOnZeroPageCount={null}
                />
              </>
            ) : (
              <BlankPage title="Ще немає жодної анульованої заявки" />
            )}
          </>
        )}
      </div>
    </PersonalLayout>
  );
};

export default AnnulledPage;
