import cn from "classnames";
import { useFormikContext } from "formik";
import { FormField, Select } from "gov-ua-ui";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import features from "features";
import { IRootState } from "reducer";
import { IDatasetState } from "scenes/Dataset/ducks";

import styles from "./editable-table.module.scss";

const UKTZED = ({ index, productTypeId, woodUuid }) => {
  const formik = useFormikContext();
  const dispatch = useDispatch();

  const { fetchingUktzedLoading } = useSelector<IRootState, IDatasetState>(
    (state) => state.dataset
  );

  const handleSelectChange = (option) => {
    formik.setFieldValue(`products.${index}.uktzed`, option.value);
  };

  useEffect(() => {
    if ((productTypeId || productTypeId === 0) && woodUuid)
      dispatch(
        features.dataset.actions.fetchUktzedRequest({
          fields: {
            productId: productTypeId,
            woodSpecieUuid: woodUuid
          },
          onSuccess: (response) => {
            if (response) {
              const parsedList = response.map((item) => {
                return { label: item, value: item };
              });

              formik.setFieldValue(`products.${index}.uktzedList`, parsedList);

              if (response.length === 1)
                formik.setFieldValue(
                  `products.${index}.uktzed`,
                  parsedList[0].value
                );
              else {
                const currentUktzed =
                  formik.values["products"][index]["uktzed"];
                const isSetted = !!parsedList.find(
                  (option) => option.value === currentUktzed
                )?.value;

                formik.setFieldValue(
                  `products.${index}.uktzed`,
                  isSetted ? currentUktzed : ""
                );
              }
            } else {
              formik.setFieldValue(`products.${index}.uktzedList`, []);
              formik.setFieldValue(`products.${index}.uktzed`, "");
            }
          }
        })
      );
    else {
      formik.setFieldValue(`products.${index}.uktzedList`, []);
      formik.setFieldValue(`products.${index}.uktzed`, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    index,
    productTypeId,
    woodUuid,
    formik.values["products"][index]["uktzed"]
  ]);

  return formik.values["products"][index]["uktzedList"]?.length <= 1 ? (
    <FormField
      name={`products.${index}.uktzed`}
      readonly
      noBorders
      className={cn(styles["table-input"], styles["table-input_has-value"])}
      errClassName={styles["table-input__error-message"]}
      widthByValue
      minWidth="40px"
      maxWidth="100px"
      textAlign="center"
      disabled={fetchingUktzedLoading}
    ></FormField>
  ) : (
    <Select
      name={`products.${index}.uktzed`}
      withFormik
      value={{
        value: formik.values["products"][index]["uktzed"],
        label: formik.values["products"][index]["uktzed"]
      }}
      options={formik.values["products"][index]["uktzedList"]}
      blurInputOnSelect={true}
      className={cn("table__input", "table__input-select", {
        "table__input--has-value":
          formik.values["products"][index]["uktzed"].length
      })}
      styles={{
        menu: (provided: any) => {
          return {
            ...provided,
            minWidth: "max-content"
          };
        },
        menuList: (provided: any) => {
          return {
            ...provided,
            fontSize: "10px"
          };
        }
      }}
      onSelectChange={handleSelectChange}
      menuPortalTarget={document.body}
      isDisabled={fetchingUktzedLoading}
    />
  );
};

export default UKTZED;
