import { takeEvery, takeLatest } from "redux-saga/effects";
import * as API from "services";
import { requestMiddleware } from "helpers";
import { actions } from "./ducks";

function* fetchSupportedDocumentsList({ payload }) {
  const request = API.fetchSupportedDocumentsList;
  const {
    fetchSupportedDocumentsListSuccess: setSuccess,
    fetchSupportedDocumentsListError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchCurrencyList({ payload }) {
  const request = API.fetchCurrencyList;
  const {
    fetchCurrencyListSuccess: setSuccess,
    fetchCurrencyListError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchDeliveryConditionList({ payload }) {
  const request = API.fetchDeliveryConditionList;
  const {
    fetchDeliveryConditionListSuccess: setSuccess,
    fetchDeliveryConditionListError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchSizeList({ payload }) {
  const request = API.fetchSizeList;
  const { fetchSizeListSuccess: setSuccess, fetchSizeListError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchStandardList({ payload }) {
  const request = API.fetchStandardList;
  const {
    fetchStandardListSuccess: setSuccess,
    fetchStandardListError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchRequestTypeList({ payload }) {
  const request = API.fetchRequestTypeList;
  const {
    fetchRequestTypeListSuccess: setSuccess,
    fetchRequestTypeListError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchUnitList({ payload }) {
  const request = API.fetchUnitList;
  const { fetchUnitListSuccess: setSuccess, fetchUnitListError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchSpeciesList({ payload }) {
  const request = API.fetchSpeciesList;
  const {
    fetchSpeciesListSuccess: setSuccess,
    fetchSpeciesListError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchSortList({ payload }) {
  const request = API.fetchSortList;
  const { fetchSortListSuccess: setSuccess, fetchSortListError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchUktzed({ payload }) {
  const request = API.fetchUktzed;
  const { fetchUktzedSuccess: setSuccess, fetchUktzedError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* generateDocumentsToDownload({ payload }) {
  const request = API.generateDocumentsToDownload;
  const {
    generateDocumentsToDownloadSuccess: setSuccess,
    generateDocumentsToDownloadError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchAccountingList({ payload }) {
  const request = API.fetchAccountingList;
  const {
    fetchAccountingListSuccess: setSuccess,
    fetchAccountingListError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchInterregionalOfficeExternalList({ payload }) {
  const request = API.fetchInterregionalOfficeExternalList;
  const {
    fetchInterregionalOfficeExternalListSuccess: setSuccess,
    fetchInterregionalOfficeExternalListError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeLatest(
    actions.fetchSupportedDocumentsListRequest().type,
    fetchSupportedDocumentsList
  );
  yield takeLatest(actions.fetchCurrencyListRequest().type, fetchCurrencyList);
  yield takeLatest(
    actions.fetchDeliveryConditionListRequest().type,
    fetchDeliveryConditionList
  );
  yield takeLatest(actions.fetchSizeListRequest().type, fetchSizeList);
  yield takeLatest(actions.fetchStandardListRequest().type, fetchStandardList);
  yield takeLatest(
    actions.fetchRequestTypeListRequest().type,
    fetchRequestTypeList
  );
  yield takeLatest(actions.fetchUnitListRequest().type, fetchUnitList);
  yield takeLatest(actions.fetchSpeciesListRequest().type, fetchSpeciesList);
  yield takeLatest(actions.fetchSortListRequest().type, fetchSortList);
  yield takeEvery(actions.fetchUktzedRequest().type, fetchUktzed);
  yield takeLatest(
    actions.fetchAccountingListRequest().type,
    fetchAccountingList
  );
  yield takeLatest(
    actions.generateDocumentsToDownloadRequest().type,
    generateDocumentsToDownload
  );
  yield takeLatest(
    actions.fetchInterregionalOfficeExternalListRequest().type,
    fetchInterregionalOfficeExternalList
  );
}
