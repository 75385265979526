import { Notification } from "gov-ua-ui";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import features from "features";
import { IRootState } from "reducer";
import { IOfficialDeclinedApplicationsForSigningState } from "../ducks";

import PersonalOfficeOfficialLayout from "components/layouts/PersonalOfficeOfficialLayout/PersonalOfficeOfficialLayout";
import DeclinedApplicationForSigning from "./components/DeclinedApplicationForSigning/DeclinedApplicationForSigning";

import Preloader from "components/Preloader/Preloader";
import styles from "./official-declined-applications-for-signing.module.scss";

const OfficialDeclinedApplicationsForSigning = () => {
  const dispatch = useDispatch();
  const { declined, isLoadingFetchDeclined } = useSelector<
    IRootState,
    IOfficialDeclinedApplicationsForSigningState
  >((state) => state.officialDeclinedApplicationsForSigning);

  useEffect(() => {
    dispatch(
      features.officialDeclinedApplicationsForSigning.actions.fetchDeclinedRequest()
    );
  }, [dispatch]);

  return (
    <PersonalOfficeOfficialLayout>
      <div className={styles["personal-cabinet-page"]}>
        {isLoadingFetchDeclined ? (
          <Preloader />
        ) : (
          <>
            {declined.map((item, index) => (
              <DeclinedApplicationForSigning key={index} application={item} />
            ))}
            {declined.length === 0 && (
              <Notification text="Всі відмовлені заяви підписані" />
            )}
          </>
        )}
      </div>
    </PersonalOfficeOfficialLayout>
  );
};

export default OfficialDeclinedApplicationsForSigning;
