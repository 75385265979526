import { createColumnHelper } from "@tanstack/react-table";
import { BlackEyeIcon, CommonButton, Pagination } from "gov-ua-ui";
import { isEmpty } from "lodash";
import { useCallback, useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";

import { applicationStatuses } from "dataset";
import features from "features";
import { formatDate } from "helpers";
import { getStartIndexOfRowByPage } from "helpers/table-helpers";

import { SearchFieldsContext } from "scenes/subject/applications/SearchApplicationsPage/_SearchApplicationsPage/_SearchApplicationsPage";
import { IFoundApplications } from "scenes/subject/applications/SearchApplicationsPage/ducks";

import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";
import Status from "components/Status/Status";
import CommonTable from "components/tables/CommonTable/CommonTable";

import CopyIcon from "assets/images/icons/copy.svg";
import DocCrossedIcon from "assets/images/icons/doc_crossed.svg";

import styles from "./applications-table.module.scss";

interface TableData {
  uuid: string;
  num: number;
  number: string;
  save_date: string;
  date: string;
  buyer: string;
  consignee: string;
  invoiceNumber: number;
  progress: number;
  percentage: string;
  rate_of_expenditure: string;
  edit: string;
  delete: string;
  actions: string;
  status: { statusText: string; statusType: StatusType };
  customsClearance?: boolean;
}

type StatusType =
  | "RECEIVED"
  | "CANCELED"
  | "ORDERED"
  | "ANNUL"
  | "DRAFT"
  | "PASSED"
  | "NOT-PASSED";

interface IApplicationsSearchResult {
  applications: IFoundApplications;
  fetchingApplicationsLoading: boolean;
}

const TABLE_ROWS_COUNT = 5;

const ApplicationsTable = ({
  applications,
  fetchingApplicationsLoading
}: IApplicationsSearchResult) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<TableData>();

  const onCopyClick = useCallback(
    (e, uuid) => {
      e.stopPropagation();

      dispatch(
        features.modal.actions.showModal({
          modalType: "CONFIRM_ACTION",
          modalProps: {
            notificationText:
              'Скопіювати заявку? Зверніть увагу, що документи з розділу заявки "Підстава видачі сертифіката" не копіюються',
            onAccept: () => {
              dispatch(
                features.modal.actions.showModal({
                  modalType: "PRELOADER",
                  modalProps: {
                    title: "Опрацювання запиту",
                    loading: true
                  }
                })
              );
              dispatch(
                features.draftApplications.actions.copyDraftRequest({
                  params: { uuid },
                  onSuccess: () => {
                    dispatch(features.modal.actions.hideModal());
                    toastr.success(
                      "Успіх",
                      "Заявка скопійована і доступна для роботи у розділі “Чернетки”"
                    );
                    dispatch(
                      features.draftApplications.actions.fetchDraftsRequest({
                        fields: {
                          page: 1,
                          limit: TABLE_ROWS_COUNT
                        }
                      })
                    );
                  },
                  onError: () => {
                    dispatch(features.modal.actions.hideModal());
                    toastr.error(
                      "Помилка",
                      "Щось пішло не так. Заявку не вдалось скопіювати"
                    );
                  }
                })
              );
            }
          }
        })
      );
    },
    [TABLE_ROWS_COUNT]
  );

  const handleAnnullClick = (path: string, e?: any) => {
    e && e.stopPropagation();
    navigate(path);
  };

  const columns = [
    columnHelper.accessor("num", {
      cell: (info: any) => (
        <p className={styles["drafts-page__table-number"]}>{info.getValue()}</p>
      ),
      header: () => "№"
    }),
    columnHelper.accessor("status", {
      cell: (info: any) => <Status {...applicationStatuses[info.getValue()]} />,
      header: () => "Статус"
    }),
    columnHelper.accessor("number", {
      cell: (info: any) => info.getValue(),
      header: () => "Номер"
    }),
    columnHelper.accessor("date", {
      cell: (info: any) => info.getValue(),
      header: () => "Дата"
    }),
    columnHelper.accessor("buyer", {
      cell: (info: any) => info.getValue(),
      header: () => "Покупець"
    }),
    columnHelper.accessor("consignee", {
      cell: (info: any) => info.getValue(),
      header: () => "Вантажоодержувач"
    }),
    columnHelper.accessor("invoiceNumber", {
      cell: (info: any) => info.getValue(),
      header: () => "Номер інвойсу"
    }),
    columnHelper.accessor("customsClearance", {
      cell: (info: any) => (
        <div className={styles["order-status"]}>
          {info.getValue() && (
            <>
              <Status title={"Пройшло"} color="black" background="#badaec" />
              <p>{info.getValue().amount}</p>
            </>
          )}
          {!info.getValue() && (
            <>
              <Status title="Не пройшло" color="black" background={"#feeceb"} />
            </>
          )}
        </div>
      ),
      header: () => "Митне оформленя"
    }),
    columnHelper.accessor("actions", {
      cell: (info: any) => {
        const {
          status,
          annulmentIsOrderedByExporter,
          annulmentIsOrderedByOfficial,
          customsClearance
        } = info.getValue();

        return (
          <div className={styles["actions-container"]}>
            <div className={styles["personal-cabinet-page__icon-buttons"]}>
              <CommonButton
                image={<BlackEyeIcon />}
                round={true}
                outlined={true}
                onClick={() => onViewClick(info.row.original.uuid)}
              />
              <CommonButton
                image={CopyIcon}
                round={true}
                outlined={true}
                className={styles["personal-cabinet-page__icon-button"]}
                onClick={(e) => onCopyClick(e, info.row.original.uuid)}
                disabled={info.row.original.completionPercentage === 0}
              />
            </div>
            {status === "received" && !customsClearance ? (
              !annulmentIsOrderedByExporter ? (
                <CommonButton
                  outlined={true}
                  className={styles["personal-cabinet-page__icon-button"]}
                  onClick={(e) =>
                    handleAnnullClick(
                      `/personal/applications/${info.row.original.uuid}/annul-request/`,
                      e
                    )
                  }
                  image={DocCrossedIcon}
                  label="Сформувати заяву про анулювання "
                  disabled={annulmentIsOrderedByOfficial}
                />
              ) : (
                <p>Заява на анулювання обробляється</p>
              )
            ) : (
              <></>
            )}
          </div>
        );
      },
      header: () => "Дії"
    })
  ];

  const applicationsOptions = useMemo(() => {
    if (isEmpty(applications?.data)) return [];

    const startIndexOfRow = getStartIndexOfRowByPage({
      currentPage: applications?.meta?.currentPage,
      itemsPerPage: applications?.meta?.itemsPerPage
    });

    return applications?.data.map((item, i) => ({
      uuid: item.uuid,
      num: startIndexOfRow + i,
      status: item.status,
      number: item.number,
      date: `${formatDate(item.date).date}\n${formatDate(item.date).time}`,
      buyer: item.buyer,
      consignee: item.consignee,
      invoiceNumber: item.invoiceNumber,
      customsClearance: item.customsClearance,
      eApplication: {
        title: "еЗаява",
        ...item.eApplication,
        date: formatDate(item?.eApplication?.date).date
      },
      eCertificate: {
        title: "єСертифікат",
        ...item.eCertificate,
        date: formatDate(item?.eCertificate?.date).date
      },
      view: "Переглянути",
      copy: "Копіювити",
      annul: "Анулювати",
      actions: {
        status: item.status,
        annulmentIsOrderedByExporter: item.annulmentIsOrderedByExporter,
        annulmentIsOrderedByOfficial: item.annulmentIsOrderedByOfficial,
        customsClearance: item.customsClearance
      }
    }));
  }, [applications]);

  const searchFields = useContext(SearchFieldsContext);

  const onPageClick = useCallback(
    (event) => {
      const newPage = event.selected + 1;

      dispatch(
        features.searchApplications.actions.fetchApplicationsRequest({
          fields: { ...searchFields.fields, page: newPage },
          onSuccess: () => {
            setTimeout(() => {
              const { offsetTop } = searchFields.resultsRef.current;
              window.scrollTo({ top: offsetTop - 150, behavior: "smooth" });
            }, 100);
          }
        })
      );
    },
    [dispatch, searchFields]
  );

  const onViewClick = useCallback((uuid) => {
    navigate(`/personal/applications/${uuid}`);
  }, []);

  return (
    <PreloaderWrapper loading={fetchingApplicationsLoading}>
      <>
        <CommonTable
          columns={columns}
          defaultData={applicationsOptions}
          tableType="ROW-GAP"
          onRowClick={onViewClick}
        />
        <Pagination
          forcePage={applications.meta.currentPage - 1}
          onPageChange={onPageClick}
          pageRangeDisplayed={applications.meta.itemsPerPage}
          pageCount={applications.meta.totalPages}
          renderOnZeroPageCount={null}
        />
      </>
    </PreloaderWrapper>
  );
};

export default ApplicationsTable;
