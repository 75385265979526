import cn from "classnames";
import { Checkbox, TooltipWidget, VisualUploadMultipleFiles } from "gov-ua-ui";
import { useDispatch } from "react-redux";

import features from "features";

import { useCallback } from "react";

import styles from "./zsn-doc-view.module.scss";

interface ZSNDocViewInterface {
  image: string;
  type: string;
  wagon: string | number;
  serial: string;
  number: string;
  date: string;
  customFields?: Array<{
    title: string;
    text: string | number;
  }>;
  name?: string;
  checked?: boolean;
  selectable?: boolean;
  selectDisabledText?: string;
  className?: string;
  files?: [];
  allowUpload?: boolean;
  onLoadFiles?: (
    acceptedFiles: any,
    rejectedFiles: any,
    replaceFile: any
  ) => void;
  onDeleteFile?: (file: any) => Promise<void>;
  isEOD?: boolean;
}

const TextItem = ({
  text,
  title
}: {
  title?: string;
  text: string | number;
}) => {
  return (
    <div className={styles["zsn-doc-view__text-item"]}>
      <p>{title}</p>
      <p>{text}</p>
    </div>
  );
};

const ZSNDocView = ({
  image,
  type,
  wagon,
  serial,
  number,
  date,
  customFields,
  name,
  checked,
  selectable,
  selectDisabledText,
  className,
  files,
  allowUpload,
  onLoadFiles,
  onDeleteFile,
  // @ts-ignore:next-line
  isEOD
}: ZSNDocViewInterface) => {
  const dispatch = useDispatch();

  const showError = useCallback(() => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "ERROR",
        modalProps: {
          title: "Увага",
          description: selectDisabledText
        }
      })
    );
  }, [dispatch, selectDisabledText]);

  const onDocumentView = (file) => {
    window.open(file.filePath, "_blank");
  };

  return (
    <div className={cn(styles["zsn-doc-view"], className)}>
      <div className={styles["zsn-doc-view__content"]}>
        {selectable && name && (
          <div onClick={selectDisabledText ? showError : undefined}>
            <Checkbox
              name={name}
              checked={checked}
              className={styles["zsn-doc-view__checkbox"]}
              disabled={!!selectDisabledText}
              onClick={selectDisabledText ? showError : undefined}
            />
          </div>
        )}
        {files?.length > 0 || allowUpload ? (
          <VisualUploadMultipleFiles
            accept={{
              "application/pdf": [".pdf"],
              "image/jpeg": [".jpg"]
            }}
            title="Завантажити скан-копію"
            readonly={!allowUpload}
            loadedFiles={files}
            onDelete={onDeleteFile}
            onDocumentView={onDocumentView}
            onLoad={onLoadFiles}
          />
        ) : (
          <div className={styles["zsn-doc-view__image"]}>
            {isEOD && (
              <TooltipWidget label="Дані по документу завантажено з системи ЕОД" />
            )}
            <img src={image} alt="Waybill" />
          </div>
        )}
        <div className={styles["zsn-doc-view__text-items"]}>
          <TextItem title="Тип" text={type} />
          <TextItem title="Вагон" text={wagon} />
          <TextItem title="Серія" text={serial} />
          <TextItem title="Номер" text={number} />
          <TextItem title="Дата" text={date} />
          {customFields &&
            customFields.map((field) => {
              return (
                <TextItem
                  key={field.title}
                  title={field.title}
                  text={field.text}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ZSNDocView;
