import { FormField } from "gov-ua-ui";

import SectionDelimiter from "components/certificateComponents/viewCertificateComponents/SectionDelimiter/SectionDelimiter";

import styles from "./summary.module.scss";

interface SummaryInterface {
  title: string;
  elements: Array<{
    name?: string;
    title: string;
    value?: string;
  }>;
}

const Summary = ({ title, elements }: SummaryInterface) => {
  return (
    <div className={styles["summary"]}>
      <SectionDelimiter className={styles["summary__delimiter"]} />
      <section className={styles["summary__info-wrapper"]}>
        <h3 className={styles["summary__summary-title"]}>{title}</h3>
        <div className={styles["summary__summary-info"]}>
          {elements.map((element) => {
            return (
              <div
                key={element?.name + element.title}
                className={styles["summary__summary-element"]}
              >
                <p>{element.title}</p>
                {element?.value != null && (
                  <p className={styles["summary__summary-value"]}>
                    {element.value}
                  </p>
                )}
                {element?.name && <FormField name={element.name} readonly />}
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default Summary;
