import {
  takeLatest,
  takeEvery,
  take,
  call,
  actionChannel
} from "redux-saga/effects";
import * as API from "services";

import { requestMiddleware } from "helpers";
import { actions } from "./ducks";

import uploadFiles from "scenes/UploadFiles/saga";

function* fetchDocument({ payload }) {
  const request = API.fetchDocumentDetailedInfo;
  const { fetchDocumentSuccess: setSuccess, fetchDocumentError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchUsedDocument({ payload }) {
  const request = API.fetchUsedDocumentDetailedInfo;
  const {
    fetchUsedDocumentSuccess: setSuccess,
    fetchUsedDocumentError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchUsedWoodCardDocument({ payload }) {
  const request = API.fetchUsedWoodCardDocumentDetailedInfo;
  const {
    fetchUsedWoodCardDocumentSuccess: setSuccess,
    fetchUsedWoodCardDocumentError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* createReturnWaste({ payload }) {
  const request = API.createReturnWaste;
  const {
    createReturnWasteSuccess: setSuccess,
    createReturnWasteError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* joinDocument({ payload }) {
  const request = API.joinDocument;
  const { joinDocumentSuccess: setSuccess, joinDocumentError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* uploadDocFile({
  payload: {
    params: { applicationUuid, basisDocumentUuid, replaceUuid },
    fields
  }
}) {
  const {
    uploadDocFileProgress: progress,
    uploadDocFileSuccess: success,
    uploadDocFileError: error,
    saveUploadDocFileCancelHandler: cancel
  } = actions;

  yield call(
    uploadFiles,
    fields,
    { progress, success, error, cancel },
    `basis_documents/${basisDocumentUuid}/files/${replaceUuid ?? ""}`,
    basisDocumentUuid
  );
}

function* deleteDocFile({ payload }) {
  const request = API.deleteBasisDocumentFile;
  const { deleteDocFileSuccess: setSuccess, deleteDocFileError: setError } =
    actions;

  if (payload.params.fileUuid) {
    yield requestMiddleware({
      ...payload,
      request,
      setSuccess,
      setError,
      isReturnRequestPayload: true
    });
  }
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchDocumentRequest().type, fetchDocument);
  yield takeLatest(actions.fetchUsedDocumentRequest().type, fetchUsedDocument);
  yield takeLatest(
    actions.fetchUsedWoodCardDocumentRequest().type,
    fetchUsedWoodCardDocument
  );
  yield takeEvery(actions.uploadDocFileRequest().type, uploadDocFile);
  yield takeEvery(actions.deleteDocFileRequest().type, deleteDocFile);
  yield takeEvery(actions.createReturnWasteRequest().type, createReturnWaste);
  const joinDocumentChannel = yield actionChannel(
    actions.joinDocumentRequest().type
  );

  while (true) {
    const action = yield take(joinDocumentChannel);
    yield call(joinDocument, action);
  }
}
