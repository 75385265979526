import { createSlice } from "@reduxjs/toolkit";
import { BASIS_DOC_TYPE } from "../interfaces/interfaces";
import partition from "lodash/partition";

export interface IProductUsageState {
  fetchingDocumentLoading: boolean;
  addingReturnWasteLoading: boolean;
  document: any;
  joiningDocumentLoading: boolean;
  isJoiningDocumentsLoaded: Array<any>;
}

const initialState: IProductUsageState = {
  fetchingDocumentLoading: null,
  addingReturnWasteLoading: null,
  joiningDocumentLoading: null,
  document: null,
  isJoiningDocumentsLoaded: []
};

export const mainSlice = createSlice({
  name: "productUsage",
  initialState,
  reducers: {
    createReturnWasteRequest: (state) => {
      state.addingReturnWasteLoading = true;
    },
    createReturnWasteSuccess: (state, action) => {
      state.addingReturnWasteLoading = false;

      const parsedProducts = partition(
        action.payload.products,
        (product) => product["isWaste"]
      );

      state.document = {
        ...action.payload,
        wasteProducts: parsedProducts[0],
        products: parsedProducts[1]
      };
    },
    createReturnWasteError: (state) => {
      state.addingReturnWasteLoading = false;
    },
    fetchDocumentRequest: (state) => {
      state.fetchingDocumentLoading = true;
    },
    fetchDocumentSuccess: (state, action) => {
      state.fetchingDocumentLoading = false;

      const parsedProducts = partition(
        action.payload.products,
        (product) => product["isWaste"]
      );

      state.document = {
        ...action.payload,
        wasteProducts: parsedProducts[0],
        products: parsedProducts[1]
      };
    },
    fetchDocumentError: (state) => {
      state.fetchingDocumentLoading = false;
      state.document = initialState.document;
    },
    fetchUsedDocumentRequest: (state) => {
      state.fetchingDocumentLoading = true;
    },
    fetchUsedDocumentSuccess: (state, action) => {
      state.fetchingDocumentLoading = false;

      const parsedProducts = partition(
        action.payload.products,
        (product) => product["isWaste"]
      );

      state.document = {
        ...action.payload,
        wasteProducts: parsedProducts[0],
        products: parsedProducts[1]
      };
    },
    fetchUsedDocumentError: (state) => {
      state.fetchingDocumentLoading = false;
      state.document = initialState.document;
    },

    fetchUsedWoodCardDocumentRequest: (state) => {
      state.fetchingDocumentLoading = true;
    },
    fetchUsedWoodCardDocumentSuccess: (state, action) => {
      state.fetchingDocumentLoading = false;

      const parsedProducts = partition(
        action.payload.products,
        (product) => product["isWaste"]
      );

      state.document = {
        ...action.payload,
        wasteProducts: parsedProducts[0],
        products: parsedProducts[1]
      };
    },
    fetchUsedWoodCardDocumentError: (state) => {
      state.fetchingDocumentLoading = false;
      state.document = initialState.document;
    },

    clearDocumentData: (state) => {
      state.document = initialState.document;
      state.isJoiningDocumentsLoaded = initialState.isJoiningDocumentsLoaded;
    },

    joinDocumentRequest: (state, action) => {
      if (action.payload.params.isPartOfMultiple)
        state.isJoiningDocumentsLoaded.push({
          uuid: action.payload.params.docId,
          loaded: false
        });
      state.joiningDocumentLoading = true;
    },
    joinDocumentSuccess: (state) => {
      state.joiningDocumentLoading = false;
    },
    joinDocumentError: (state) => {
      state.joiningDocumentLoading = false;
    },
    joinDocumentReset: (state) => {
      state.isJoiningDocumentsLoaded = initialState.isJoiningDocumentsLoaded;
    },
    updateIsJoiningDocumentsLoaded: (state, action) => {
      state.isJoiningDocumentsLoaded = state.isJoiningDocumentsLoaded.map(
        (item) => (item.uuid === action.payload ? (item.loaded = true) : item)
      );
    },

    uploadDocFileRequest: (state, { payload }) => {
      const replaceUuid = payload.params.replaceUuid;

      const file = payload?.fields?.file;
      const fileData = {
        name: file.name,
        progress: 0,
        size: file.size,
        createdAt: file.createdAt,
        type: file.alias
      };

      if (file.alias === BASIS_DOC_TYPE.ZSN_SCANCOPY) {
        state.document.children = state.document.children.map((child) => {
          if (child.uuid === payload.params.basisDocumentUuid) {
            if (replaceUuid) {
              return {
                ...child,
                files: child.files.map((item) =>
                  item.uuid === replaceUuid && item.type === file.alias
                    ? fileData
                    : item
                )
              };
            } else {
              return {
                ...child,
                files: [...child.files, fileData]
              };
            }
          } else return child;
        });
        return;
      }
      if (replaceUuid) {
        state.document.files = state.document.files.map((item) =>
          item.uuid === replaceUuid && item.type === file.alias
            ? fileData
            : item
        );
      } else {
        state.document.files.push(fileData);
      }
    },
    uploadDocFileProgress: (state, { payload }) => {
      if (payload.basisDocumentUuid) {
        state.document.children
          .find((child) => child.uuid === payload.basisDocumentUuid)
          .files.find(
            (file) =>
              file.name === payload.fileName && file.type === payload.alias
          ).progress = payload.progress;
        return;
      }

      state.document.files.find(
        (file) => file.name === payload.fileName && file.type === payload.alias
      ).progress = payload.progress;
    },
    uploadDocFileSuccess: (state, { payload }) => {
      if (payload.basisDocumentUuid) {
        state.document.children = state.document.children.map((child) =>
          child.uuid === payload.basisDocumentUuid
            ? {
                ...child,
                files: child.files.map((file) =>
                  file.name === payload.fileName && file.type === payload.alias
                    ? payload
                    : file
                )
              }
            : child
        );
        return;
      }

      const index = state.document.files.findIndex(
        (file) => file.name === payload.fileName && file.type === payload.alias
      );
      state.document.files[index] = payload;
    },
    uploadDocFileError: (state, { payload }) => {
      state.document.files = state.document.files.filter((item) => {
        if (item.type !== payload.file.alias) return true;
        else return item.uuid !== payload.file.uuid;
      });
    },
    saveUploadDocFileCancelHandler: (state, { payload }) => {
      if (payload.basisDocumentUuid) {
        state.document.children = state.document.children.map((child) =>
          child.uuid === payload.basisDocumentUuid
            ? {
                ...child,
                files: child.files.map((file) =>
                  file.name === payload.fileName && file.type === payload.alias
                    ? {
                        ...file,
                        cancel: payload.cancel
                      }
                    : file
                )
              }
            : child
        );
        return;
      }

      state.document.files = state.document.files.map((item) => {
        if (item.name === payload.fileName && item.type === payload.alias) {
          return {
            ...item,
            cancel: payload.cancel
          };
        }
        return item;
      });
    },

    deleteDocFileRequest: (state, { payload }) => {
      if (!payload.params.fileUuid && payload.name) {
        state.document.files = state.document.files.filter((item) => {
          if (item.type !== payload.type) return true;
          else return item.name !== payload.name;
        });
      }
    },
    deleteDocFileSuccess: (state, { payload }) => {
      if (payload?.data?.type === BASIS_DOC_TYPE.ZSN_SCANCOPY) {
        state.document.children = state.document.children.map((child) =>
          child.uuid === payload.params.documentUuid
            ? {
                ...child,
                files: child.files.filter((item) => {
                  if (item.type !== payload?.data.type) return true;
                  else return item.uuid !== payload?.data.uuid;
                })
              }
            : child
        );
        return;
      }

      state.document.files = state.document.files.filter((item) => {
        if (item.type !== payload.data.type) return true;
        else return item.uuid !== payload.data.uuid;
      });
    },
    deleteDocFileError: () => {}
  }
});

export const { actions, reducer } = mainSlice;
