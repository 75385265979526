import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import features from "features";
import { IRootState } from "reducer";
import { IProductUsageState } from "scenes/subject/documents/ProductUsage/ducks";

import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";
import SpecifyCurrentUsageLayout from "components/layouts/SpecifyCurrentUsageLayout/SpecifyCurrentUsageLayout";
import SpecifyCurrentZNUsageLayout from "components/layouts/SpecifyCurrentZNUsageLayout/SpecifyCurrentZNUsageLayout";

const _SpecifyCurrentUsagePage = () => {
  const dispatch = useDispatch();

  const { applicationId, docId, type, chain } = useParams();
  const {
    document,
    fetchingDocumentLoading,
    joiningDocumentLoading,
    addingReturnWasteLoading
  } = useSelector<IRootState, IProductUsageState>(
    (state) => state.productUsage
  );

  useEffect(() => {
    if (applicationId) {
      dispatch(
        features.application.actions.fetchApplicationRequest({
          params: { uuid: applicationId }
        })
      );

      if (docId) {
        dispatch(
          features.productUsage.actions.fetchDocumentRequest({
            params: {
              applicationUuid: applicationId,
              uuid: docId
            }
          })
        );
      }
    }

    return () => {
      dispatch(features.application.actions.clearApplication());
      dispatch(features.subjectDocs.actions.clearDoc());
    };
  }, []);

  return (
    <PreloaderWrapper
      loading={
        fetchingDocumentLoading ||
        joiningDocumentLoading ||
        addingReturnWasteLoading
      }
      withModal
    >
      {document && (
        <>
          {type === "zn" ? (
            <SpecifyCurrentZNUsageLayout
              chain={chain}
              data={document}
              applicationId={applicationId}
              docId={docId}
            />
          ) : (
            <SpecifyCurrentUsageLayout
              docType={type}
              chain={chain}
              data={document}
              applicationId={applicationId}
              docId={docId}
            />
          )}
        </>
      )}
    </PreloaderWrapper>
  );
};

export default _SpecifyCurrentUsagePage;
