import cn from "classnames";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import features from "features";

import Preloader from "components/Preloader/Preloader";

import styles from "./preloader-wrapper.module.scss";

interface IPreloaderWrapper {
  loading?: boolean | string;
  center?: boolean;
  children?: any;
  opacity?: boolean;
  withModal?: boolean;
  isUnblocked?: boolean;
}

const PreloaderWrapper = (props: IPreloaderWrapper) => {
  const { loading, center, children, opacity, withModal, isUnblocked } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (withModal && loading)
      dispatch(
        features.modal.actions.showModal({
          modalType: "PRELOADER",
          modalProps: {
            title: "Опрацювання запиту",
            loading: true,
            disableClose: true
          }
        })
      );
    else if (withModal && !loading) {
      dispatch(features.modal.actions.hideModal());
    }
  }, [withModal, loading, dispatch]);

  return (
    <div
      className={cn(styles["preloader-container"], {
        [styles["preloader-container-loading"]]: loading,
        [styles["center"]]: center,
        [styles["opacity"]]: opacity,
        [styles["unblocked"]]: isUnblocked
      })}
    >
      {loading && <Preloader isChildPreloader isAbsolute={isUnblocked} />}
      {children}
    </div>
  );
};

export default PreloaderWrapper;
